export const Security = ({
  height = '24',
  width = '24',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M20.9949 6.89998C20.9783 6.73193 20.9193 6.57084 20.8235 6.4318C20.7276 6.29276 20.5981 6.18031 20.4469 6.10498L12.4469 2.10498C12.308 2.03546 12.1548 1.99927 11.9994 1.99927C11.8441 1.99927 11.6909 2.03546 11.5519 2.10498L3.55194 6.10498C3.40122 6.18074 3.27206 6.29332 3.17643 6.4323C3.08081 6.57127 3.02183 6.73214 3.00494 6.89998C2.99394 7.00698 2.04394 17.667 11.5939 21.914C11.7215 21.9716 11.8599 22.0014 11.9999 22.0014C12.1399 22.0014 12.2783 21.9716 12.4059 21.914C21.9559 17.667 21.0059 7.00798 20.9949 6.89998ZM11.9999 19.897C5.23094 16.625 4.91094 9.64198 4.96594 7.63498L11.9999 4.11798L19.0289 7.63298C19.0659 9.62198 18.7009 16.651 11.9999 19.897Z" fill={color} />
      <path d="M11 12.586L8.70697 10.293L7.29297 11.707L11 15.414L16.707 9.70703L15.293 8.29303L11 12.586Z" fill={color} />
    </svg>
  )
}

export default Security
