import {
    Box,
    Button,
    Center,
    Input,
    useToast,
    Image,
    Text,
    Flex
} from '@chakra-ui/react'
import { fetcher, UserEndpoints } from '@olagg/api-hooks'
import { useAuthStore, useOnboardingStore } from '@olagg/store'
import { useContext, useEffect, useRef, useState } from 'react'
import avatar from '@olagg/ui-kit/svg/avatar_circle.svg'
import Modal from '../../../components/Modal/Modal'
import { analytics } from '../../../analytics'
import { useTranslation } from 'react-i18next';
import { AdTrackingContext } from '@/contexts/adTrackingContext'

type Props = {
    onClose: () => void,
    isOpen: boolean,
    title?: string
    subtitle?: string
}

const ModalEditProfile = ({ onClose, isOpen, title, subtitle }: Props) => {
    const { fcb, fbp } = useContext(AdTrackingContext)
    const { t } = useTranslation();
    const { me, getMe } = useAuthStore()
    const toast = useToast()
    const modalTitle: string = title || t('myAccount.profile.editModal.title')

    const inputFile = useRef<HTMLInputElement | null>(null)
    const inputName = useRef<HTMLInputElement | null>(null)

    const [imgPreview, setImgPreview] = useState<
        string | ArrayBuffer | null
    >(null)

    const [isLoading, setIsLoading] = useState(false)
    const [currentUserAvatar, setCurrentUserAvatar] = useState(avatar)
    const [emptyNameError, setEmptyNameError] = useState(false)

    const [avatarUpdated, setAvatarUpdated] = useState(false)

    const { obQuests, obXp, setObXp } = useOnboardingStore()

    useEffect(() => {
        if (me && me.avatar) {
            setCurrentUserAvatar(me.avatar)
        } else {
            setCurrentUserAvatar(avatar)
        }
    }, [me])

    useEffect(() => {
        if (me && me.avatar && avatarUpdated) {
            let props = {
                avatar: me?.avatar,
                user_agent: navigator.userAgent
            }
            if (fcb != null) props.fbc = fcb
            if (fbp != null) props.fbp = fbp

            analytics()?.track('User Picture Updated', props)

            analytics()?.identify(`${me?.id}`, {
                avatar: me?.avatar,
                user_agent: navigator.userAgent
            })

            setAvatarUpdated(false)
        }
    }, [avatarUpdated])

    const getXp = async () => {

        const triggerQuestAlias = 'user.name.set'
        const triggerQuestPic = 'user.avatar.updated'

        let totalXp = obXp

        let alias_quest = obQuests?.quests.filter(f => f.trigger == triggerQuestAlias)
        let pic_quest = obQuests?.quests.filter(f => f.trigger == triggerQuestPic)

        if (alias_quest.length > 0) {
            totalXp += alias_quest[0].score
        }

        if (pic_quest.length > 0 && inputFile.current?.files.length > 0) {
            totalXp += pic_quest[0].score
        }

        setObXp(totalXp)

        onClose()
    }

    const handleChange = (e: any) => {
        const selectedFile = e.target.files[0]
        if (selectedFile.size > 5000000) {
            toast({
                title: t('myAccount.profile.editModal.error.bigImage'),
                description: t('myAccount.profile.editModal.error.bigImageDescription'),
                status: 'error',
                position: 'bottom-right',
                duration: 2000,
                isClosable: true
            })
            return
        }

        const reader = new FileReader()
        reader?.addEventListener('load', () => {
            setImgPreview(reader.result)
        })
        reader?.readAsDataURL(selectedFile)
    }

    const onSubmit = () => {
        if (inputName.current?.value == '') {
            setEmptyNameError(true)
            return
        } else {
            setEmptyNameError(false)
        }

        setIsLoading(true)

        const formData = new FormData()
        formData.append('user[name]', inputName.current?.value)
        if (inputFile.current?.files.length > 0) formData.append('user[avatar]', inputFile.current?.files[0])

        fetcher(UserEndpoints.updateAvatar(me.id, formData))
            .then(async () => {
                toast({
                    title: t('myAccount.profile.editModal.success'),
                    colorScheme: 'olaggPink',
                    status: 'success',
                    position: 'bottom-right',
                    duration: 2000,
                    isClosable: true
                })
                await getMe()

                if (inputName.current?.value != '') {

                    let props = {
                        name: inputName.current?.value,
                        user_agent: navigator.userAgent
                    }
                    if (fcb != null) props.fbc = fcb
                    if (fbp != null) props.fbp = fbp

                    analytics()?.track('User Name Updated', props)
                }

                if (inputFile.current?.files.length > 0) {
                    setAvatarUpdated(true)
                }

                analytics()?.identify(`${me?.id}`, {
                    name: inputName.current?.value,
                    user_agent: navigator.userAgent
                })

                getXp()
            })
            .catch(() =>
                toast({
                    title: t('myAccount.profile.editModal.error.tryAgain'),
                    status: 'error',
                    position: 'bottom-right',
                    duration: 2000,
                    isClosable: true
                })
            )
            .finally(() => setIsLoading(false))
    }

    return (
        <Modal onClose={onClose} isOpen={isOpen} modalHeader={<ModalHeader title={modalTitle} />} size={{ base: 'xs', md: 'md' }}>
            <Box height={'auto'}>
                {subtitle && <Text fontSize='16px' fontWeight='600' color='#3F2091' textAlign='center' mb='15px'>
                    {subtitle}
                </Text>}
                <Center position="relative">
                    {!imgPreview && (
                        <Box border="2px solid purple" borderRadius='50%' backgroundColor="purple">
                            <Image
                                w="100px"
                                h="100px"
                                fallbackSrc={avatar}
                                src={currentUserAvatar}
                                objectFit={'cover'} loading='lazy' borderRadius='50%'
                            />
                        </Box>
                    )}
                    {typeof imgPreview === 'string' && (
                        <Box border="2px solid purple" borderRadius='50%' backgroundColor="purple">
                            <Image
                                w="100px"
                                h="100px"
                                fallbackSrc={avatar}
                                src={imgPreview}
                                objectFit={'cover'} loading='lazy' borderRadius='50%'
                            />
                        </Box>
                    )}
                </Center>
                <Center flexDirection="column" columnGap={15} p={5}>
                    <Button
                        variant='link'
                        color='#4E10FF'
                        fontSize="16px"
                        fontWeight='600'
                        onClick={() => inputFile.current?.click()}
                    >
                        {!imgPreview ? t('myAccount.profile.editModal.editProfilePicture') : t('myAccount.profile.editModal.takeAnotherPicture')}
                    </Button>

                    <Flex direction='column' width='100%' height='105px' mt='15px' justifyContent={'center'}>
                        <Text fontWeight='600' fontSize='16px' mb='5px' textAlign={'center'}>{t('myAccount.profile.editModal.howToCallYou')}</Text>
                        <Input
                            placeholder='Nombre/alias'
                            variant='outline'
                            sx={{
                                width: '100%',
                                borderWidth: '2px',
                                borderColor: '#3F2091',
                                fontSize: '16px',
                                fontWeight: '600',
                                height: '44px',
                                minHeight: '44px'
                            }}
                            defaultValue={me?.name}
                            ref={inputName}
                        />
                        {emptyNameError && (
                            <Text color='red' fontWeight='600' mt='5px' fontSize='13px'>{t('myAccount.profile.editModal.error.emptyAlias')}</Text>
                        )}
                    </Flex>

                    <Button
                        p={'0 10px'}
                        w="full"
                        variant="filledGradient"
                        fontSize="16px"
                        fontWeight="600"
                        cursor={'pointer'}
                        onClick={onSubmit}
                    >
                        {t('myAccount.profile.editModal.saveChanges')}
                    </Button>
                </Center>
                <Input
                    display="none"
                    type="file"
                    accept=".png,.jpg,.gif,.jpeg"
                    ref={inputFile}
                    id="file"
                    onChange={handleChange}
                />
            </Box>
        </Modal>
    )
}

const ModalHeader = ({ title }: { title: string }) => {
    return (
        <Box padding={5}>
            <Text
                fontWeight='600'
                fontSize='24px'
                color='#3F2091'
            >
                {title}
            </Text>
        </Box>
    )
}

export default ModalEditProfile
