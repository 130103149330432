import { Box, Button, Flex, Grid, GridItem, Show, Text, useMediaQuery } from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next';

import { FC } from 'react'
import Investors from './investors'
import { Link } from 'react-router-dom'
import LogoOlaGG from './assets/logo'
import { ROUTES } from '../../routes';
import Social from './Social'
import { openConsentManager } from '@segment/consent-manager';
import { useLocation } from "react-router-dom"

const Footer: FC = () => {
    const { t } = useTranslation();
    const [isLargerThan992] = useMediaQuery('(min-width: 992px)')
    const maxWidth = import.meta.env.VITE_LAYOUT_MAXWIDTH;
    const { pathname } = useLocation();
    const showInvestors = pathname == ROUTES.ABOUT || pathname == ROUTES.B2B;

    const data_links = [
        { text: t('footer.links.about_ola'), link: ROUTES.ABOUT, isLink: true, fromLogo: true },
        { text: t('footer.links.terms_and_conditions'), link: '/posts/tyc', isLink: true },
        { text: t('footer.links.cookie_policy'), link: '/posts/cookies', isLink: true },
        { text: t('footer.links.scholarship_policy'), link: '/posts/politica-becas', isLink: true },
        { text: t('footer.links.faq'), link: '/landing/faqs', isLink: true },
        { text: t('footer.links.privacy_policy'), link: '/posts/privacidad', isLink: true },
        { text: t('footer.links.cookie_preferences'), link: '/', isLink: false, click: openConsentManager },
        { text: t('footer.links.support'), link: 'mailto:soporte@olagg.io', isLink: false },
    ]

    return (
        <Box
            pt={isLargerThan992 ? '20' : '20'}
            pb={'12'}
            color='#FFFFFF'
            backgroundColor='#2B2640'
            fontSize='18px'
            fontWeight='600'
            textAlign='left'
            px={{ base: '16px', lg: '48px' }}
        >
            <Flex
                margin='0 auto'
                maxW={maxWidth}
                flexDirection='column'
                gap='48px'
            >
                {
                    showInvestors && (
                        <Investors />
                    )
                }
                <Social />

                <Flex direction={{
                    base: 'column-reverse',
                    md: 'row'
                }} alignItems={'center'}>
                    <Flex flexDirection={'column'} mt={{ base: '48px', md: 'inherit' }} alignSelf={{ base: 'inherit', md: 'flex-start' }}>
                        <Link to={ROUTES.HOME} state={{ fromLogo: true }}>
                            <LogoOlaGG />
                        </Link>
                        <Text fontSize='14px' mt='8px' fontWeight={'400'} lineHeight='18px' maxW='201px' textAlign={{ base: 'center', md: 'left' }}>
                            {t('footer.logoText')}
                        </Text>
                    </Flex>
                    <Show above='md'>
                        <Flex ml={{ base: 'inherit', md: 'auto' }} alignSelf='flex-start' mt='8px'>
                            <Link to={ROUTES.B2B} state={{ fromLogo: false }}>
                                <Button as='span' variant='link' color={'white'} sx={{
                                    '&:hover': {
                                        color: '#FF00F6'
                                    }
                                }}>
                                    <Text as='span' fontSize='14px' fontWeight='600' whiteSpace='break-spaces' textAlign='left'>{t('footer.links.alliesWithOla')}</Text>
                                </Button>
                            </Link>
                        </Flex>
                    </Show>

                    <Flex ml={{ base: 'inherit', md: 'auto' }}>
                        <Grid columnGap={{ base: '25px', md: '68px' }} rowGap={{ base: '10px', md: '20px' }} px='20px' templateColumns={{
                            base: 'repeat(2, 1fr)',
                            lg: 'repeat(4, 1fr)'
                        }}>
                            <Show below='md'>
                                <Flex ml={{ base: 'inherit', md: 'auto' }} alignSelf='flex-start' mt={{base: 0, md: '8px'}}>
                                    <Link to={ROUTES.B2B} state={{ fromLogo: false }}>
                                        <Button as='span' variant='link' color={'white'} sx={{
                                            '&:hover': {
                                                color: '#FF00F6'
                                            }
                                        }}>
                                            <Text as='span' fontSize='14px' fontWeight='600' whiteSpace='break-spaces' textAlign='left'>{t('footer.links.alliesWithOla')}</Text>
                                        </Button>
                                    </Link>
                                </Flex>
                            </Show>
                            {data_links.map((l, i) => (
                                <GridItem key={i}>
                                    {l.isLink ? (
                                        <Link to={l.link} state={{ fromLogo: l.fromLogo }}>
                                            <Button as='span' variant='link' color={'white'} sx={{
                                                '&:hover': {
                                                    color: '#FF00F6'
                                                }
                                            }}>
                                                <Text as='span' fontSize='14px' fontWeight='600' whiteSpace='break-spaces' textAlign='left'>{l.text}</Text>
                                            </Button>
                                        </Link>
                                    ) : (
                                        l.click ? (
                                            <Button onClick={l.click} variant='link' color={'white'} sx={{
                                                '&:hover': {
                                                    color: '#FF00F6'
                                                }
                                            }}>
                                                <Text as='span' fontSize='14px' fontWeight='600' whiteSpace='break-spaces' textAlign='left'>{l.text}</Text>
                                            </Button>
                                        ) : (
                                            <a href={l.link}>
                                                <Button as='span' variant='link' color={'white'} sx={{
                                                    '&:hover': {
                                                        color: '#FF00F6'
                                                    }
                                                }}>
                                                    <Text as='span' fontSize='14px' fontWeight='600' whiteSpace='break-spaces' textAlign='left'>{l.text}</Text>
                                                </Button>
                                            </a>
                                        )
                                    )}
                                </GridItem>
                            ))}
                        </Grid>
                    </Flex>
                </Flex>
            </Flex>
            <Text mt={{
                base: '48px',
                md: '68px'
            }} textAlign={'center'} fontSize='14px' fontWeight={'300'}>{t('footer.copyright')}</Text>
        </Box>
    )
}

export default Footer
