/**
 * El Consent-Manager para segment (https://github.com/segmentio/consent-manager) solo 
 * funciona por ahora con la version snippet de analytics.js (script en el index.html)
 * 
 * Se comenta todo lo relacionado a analytics-next (https://www.npmjs.com/package/@segment/analytics-next)
 * y se crea analytics() para mantener la sintaxis actual. Se sacan los .catch(), ya que rompe cuando
 * window.analytics todavia no terminó de cargar (estado intermedio, existen los metodos pero como array)
 * 
 * Se comenta tambien loadAnalytics() en main.tsx, para que la carga la haga el
 * consent-manager en funcion del input del usuario/region
 * 
 */

import { AnalyticsBrowser } from '@segment/analytics-next'

// let _analytics: AnalyticsBrowser | null = null

// export function loadAnalytics() {
//   try {
//     _analytics = AnalyticsBrowser.load(
//       {
//         writeKey: import.meta.env.VITE_SEGMENT_KEY || '2bVFMstw3pn6OQgzDOndHgqzHd20eRIq',
//         cdnURL: 'https://analytics-cdn.olagg.io'
//       },
//       {
//         integrations: {
//           'Segment.io': {
//             apiHost: 'analytics.olagg.io/v1',
//             protocol: 'https' // optional
//           }
//         }
//       }
//     )
//   } catch (e) {
//     catchSegmentError(e)
//   }
// }

// export const analytics = () => _analytics

export const catchSegmentError = (error: any) => {
  console.info(
    '[Segment error] User probably has adblock activated',
    error
  )
}

export const analytics = () => window.analytics;
