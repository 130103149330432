import { Flex, Text, Image } from "@chakra-ui/react";
import { FaHeart, FaRegHeart } from "react-icons/fa";

interface ItemProps {
  image?: string;
  selected?: boolean;
  text: string;
  width?: string;
  height?: string;
}

const PlatformsItem = ({
  image,
  selected = false,
  text,
  width = '156px',
  height = '156px'
}: ItemProps) => {

  return (

    <>
      <Flex overflow={'hidden'}
        sx={{
          borderRadius: '16px',
          border: selected ? '2px solid #0000' : '2px solid transparent',
          background: selected ? 'linear-gradient(220deg, #F8AC33 0%, #912FD4 150%) border-box' : 'transparent'
        }}
        position='relative'
      >
        <Image width={width} height={height} objectFit={'cover'} src={image ? image : '/images/onboarding/platform_no_image.png'} />
        <Flex
          position={'absolute'}
          bottom='6px'
          right='6px'
          backgroundColor={selected ? 'white' : 'rgba(255,255,255, 0.3)'}
          width='26px'
          height='26px'
          minWidth='26px'
          minHeight='26px'
          justifyContent={'center'}
          alignItems={'center'}
          borderRadius='50%'
        >
          {selected ? <FaHeart color='#c26b87' fontSize={'12px'} /> : <FaRegHeart color='white' fontSize={'12px'} />}
        </Flex>
      </Flex>

      <Text mt='8px' fontSize={'14px'} fontWeight='700' color={'white'}>{text}</Text>
    </>
  )
}

export default PlatformsItem;
