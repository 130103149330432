import { RankingFilter } from "@olagg/api-hooks";
import { MdTour } from 'react-icons/md';
import { GiFlagObjective } from 'react-icons/gi';
import { RiGlobalLine } from 'react-icons/ri';
import { ROUTES } from "../../routes";

export const tabs = {
  Tournaments: {
    index: 0,
    label: 'tournaments',
    hashtag: '#torneos',
  },
  Quests: {
    index: 1,
    label: 'quests',
    hashtag: '#misiones',
  },
  UserContent: {
    index: 2,
    label: 'content',
    hashtag: '#contenido',
  },
  General: {
    index: 3,
    label: 'general',
    hashtag: '#general',
  },
};

export const commonTypeFilters: Array<RankingFilter> = [
  {
    label: tabs['General'].label,
    currentTabIndex: tabs['General'].index,
    labelMobile: RiGlobalLine,
  },
  {
    label: tabs['Tournaments'].label,
    labelMobile: MdTour,
    currentTabIndex: tabs['Tournaments'].index,
  },
  {
    label: tabs['Quests'].label,
    labelMobile: GiFlagObjective,
    currentTabIndex: tabs['Quests'].index,
  },
  {
    label: tabs['UserContent'].label,
    labelMobile: 'C.',
    currentTabIndex: tabs['UserContent'].index,
  },
];

export const commonTimeFilters: Array<RankingFilter> = [
  {
    label: 'Temporada',
    from: '2024-02-07T04:00:00.000Z',
    to: '2024-03-26T00:00:00.000Z',
  },
  {
    label: 'Desde Sep 2023',
    from: '2023-09-01T00:00:00.000Z',
    to: null,
  },
  {
    label: '7 días',
    from: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString(),
    to: null,
  },
  {
    label: '30 días',
    from: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString(),
    to: null,
  }
];

export const Cta = {
  [tabs.General.index]: { route: ROUTES.GAMES, label: 'play' },
  [tabs.Tournaments.index]: { route: ROUTES.TORNEOS, label: 'participate' },
  [tabs.Quests.index]: { route: ROUTES.QUESTS, label: 'complete' },
  [tabs.UserContent.index]: { route: ROUTES.GAMES, label: 'share' },
}
