import { FC, ReactNode } from 'react';
import { FormProvider } from 'react-hook-form';

/**
 * Customized Form component for use in OlaGG forms
 * 
 * Implements React Hook Forms and validations
 * 
 * @example 
 * 
 * 
 */

interface IOlaggForm {
    useFormMethods: any,
    onSubmit: (dataForm: any, e?: Event) => void,
    style?: React.CSSProperties,
    children: ReactNode,
};

const OlaggForm: FC<IOlaggForm> = ({ 
    useFormMethods,
    onSubmit,
    style = {},
    children 
}) => {
    return (
        <FormProvider {...useFormMethods}>
            <form onSubmit={useFormMethods.handleSubmit(onSubmit)} style={style}>
                {children}
            </form>
        </FormProvider>
    );
}

export default OlaggForm;
