export const Logout2 = ({
  height = '16',
  width = '16',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (

    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
    >
      <path
      d="M12 4.00005L10.872 5.12805L12.936 7.20005H4.8V8.80005H12.936L10.872 10.864L12 12L16 8.00005M1.6 2.40005H8V0.800049H1.6C0.72 0.800049 0 1.52005 0 2.40005V13.6C0 14.48 0.72 15.2 1.6 15.2H8V13.6H1.6V2.40005Z"
      fill={color}/>
    </svg>
  )
}

export default Logout2