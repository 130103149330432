import { fetcher, ReferralsEndpoints, useQ } from '@olagg/api-hooks';
import { RelevantAction } from '@olagg/db-types';
import { useAuthStore } from '@olagg/store';

export const REFER_FRIEND_ID = 1;

export const useReferrals = () => {
    const { me } = useAuthStore();
    
    const response = useQ<{
        relevantActions: RelevantAction[],
        meta: {count: number, totalPrizes: number, totalPrizesPending: number}
    }>(ReferralsEndpoints.all());
    
    const generateReferrerId = (action: ActionType) => me?.id ? `?referrerId=${action.toString()}T${me.id}` : '';
    
    const validateRelevantAction = (action: ActionType) => {
        const referrerId = localStorage.getItem('referrerId');
        const referredAction = referrerId?.split('T')[0];

        if (referrerId && referredAction === action.toString()) {
          fetcher(ReferralsEndpoints.validateAction(referrerId))
            .then(() => localStorage.removeItem('referrerId'))
            .catch((e) => console.log(e))
        };
    }

    enum ActionType {
        REFER_FRIEND = 1,
        PARTICIPATE_TOURNAMENT = 2,
        VOTE_NEWS = 3,
        RATE_GAME = 4,
    }
    
    return {
        ...response,
        generateReferrerId,
        validateRelevantAction,
        ActionType,
    }
}
