import { Avatar2, DownArrow, Gift, Logout2, Medal, ReferFriend, ScholarshipIcon, Security, UpArrow } from '@olagg/ui-kit';
import { FaDollarSign } from 'react-icons/fa'
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'

import { FaRegCopy } from 'react-icons/fa'
import Level from '../Level/Level';
import UserNav from "./UserNav"
import { ROUTES } from '../../routes'
import { useAuthStore } from '@olagg/store'
import { useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import ShareModal from '../../components/ShareModal'
import { useReferrals } from '@/modules/Referrals/hooks/useReferrals';

const MyAccountNav = () => {
  const { me, logout } = useAuthStore()
  const { t } = useTranslation()
  const toast = useToast()
  const navigate = useNavigate()

  const { generateReferrerId, ActionType } = useReferrals();
  const referrerId = generateReferrerId(ActionType.REFER_FRIEND);

  const { isOpen: isOpenShare, onOpen: onOpenShare, onClose: onCloseShare } = useDisclosure();

  const handleLogout = () => {
    logout()
    navigate(ROUTES.HOME)
  }

  const copyId = () => {
    navigator.clipboard.writeText(me.id)

    toast({
      title: `ID ${me.id} copiada!`,
      status: 'success',
      position: 'bottom-right',
      duration: 5000,
      isClosable: true
    });
  }

  return (
    <Menu direction='rtl'>
      {({ isOpen }) => (
        <>
          <MenuButton>
            <Flex alignItems="center">
              <Box>
                <UserNav />
              </Box>
              {
                isOpen ? (
                  <UpArrow style={{ marginLeft: '10px' }} />
                ) : (
                  <DownArrow style={{ marginLeft: '10px' }} />
                )
              }
            </Flex>
          </MenuButton>

          <MenuList
            bg='#110434'
            borderWidth='0px'
            w='290px'
            padding={'16px'}
            border='1px solid rgba(255, 255, 255, 0.24)'
            boxShadow={'0px 4px 24px 0px rgba(70, 70, 70, 0.25)'}

            as='ul'
            height={{base: 'calc(100vh - 82px)', md: 'auto'}}
            overflowY='auto'
            sx={{
              listStyle: 'none'
            }}
          >
            <Text
              fontSize='18px'
              fontWeight={'500'}
              color={'#fff'}
            >
              {me.name}
            </Text>
            <MenuItem
              bg='#110434'
              color='#fff'
              fontSize='16px'
              onClick={copyId}
              closeOnSelect={false}
              padding='0px'
            >
              ID {me.id} <FaRegCopy style={{ marginLeft: '6px' }} color='#fff' size={14} />
            </MenuItem>


            <MenuItem
              marginTop='12px'
              marginBottom='24px'
              bg='#110434'
              as={'div'}
              padding='0'
            >
              <ShareModal
                title={t('myAccount.referrals.shareModalTitle')}
                description={t('myAccount.referrals.shareModalDesc')}
                link={`${window.location.origin}/login${referrerId}`}
                isOpen={isOpenShare}
                onClose={onCloseShare}
              />
              <Button variant='filledGradient' w={'100%'} maxH='48px' fontSize={'14px'} onClick={onOpenShare} gap='8px'>
                <ReferFriend color='white' />
                {t('myAccount.referrals.inviteFriendsCTA')}
              </Button>
            </MenuItem>


            <Level />
            <Link to={ROUTES.MY_PROFILE}>
              <MenuItem
                bg='#110434'
                color='#fff'
                _hover={{ fontWeight: 'bold' }}
                fontSize='16px'
                marginTop='24px'
                icon={<Avatar2 width='16px' height='16px' />}
              >
                {t('navbar.myAccountNav.myProfile')}
              </MenuItem>
            </Link>
            <Link to={ROUTES.SECURITY}>
              <MenuItem
                bg='#110434'
                color='#fff'
                _hover={{ fontWeight: 'bold' }}
                fontSize='16px'
                marginTop='12px'
                icon={<Security width='16px' height='16px' />}
              >
                {t('navbar.myAccountNav.security')}
              </MenuItem>
            </Link>
            <Link to={ROUTES.TOKENS}>
              <MenuItem
                bg='#110434'
                color='#fff'
                _hover={{ fontWeight: 'bold' }}
                fontSize='16px'
                marginTop='12px'
                icon={<Medal width='16px' height='16px' />}
              >
                {t('navbar.myAccountNav.myTokens')}
              </MenuItem>
            </Link>
            <Link to={ROUTES.REWARDS}>
              <MenuItem
                bg='#110434'
                color='#fff'
                _hover={{ fontWeight: 'bold' }}
                fontSize='16px'
                marginTop='12px'
                icon={<Gift width='16px' height='16px' />}
              >
                {t('navbar.myAccountNav.myRewards')}
              </MenuItem>
            </Link>
            <Link to={ROUTES.SCHOLARSHIPS}>
              <MenuItem
                bg='#110434'
                color='#fff'
                _hover={{ fontWeight: 'bold' }}
                fontSize='16px'
                marginTop='12px'
                icon={<ScholarshipIcon width='16px' height='16px' />}
              >
                {t('navbar.myAccountNav.myScholarships')}
              </MenuItem>
            </Link>
            <Link to={ROUTES.REFERRALS}>
              <MenuItem
                bg='#110434'
                color='#fff'
                _hover={{ fontWeight: 'bold' }}
                fontSize='16px'
                marginTop='12px'
                icon={<ReferFriend width='16px' height='16px' />}
              >
                {t('navbar.myAccountNav.myReferrals')}
              </MenuItem>
            </Link>
            <Link to={ROUTES.MY_PAYMENTS}>
              <MenuItem
                bg='#110434'
                color='#fff'
                _hover={{ fontWeight: 'bold' }}
                fontSize='16px'
                marginTop='12px'
                icon={<Flex width={'18px'} height={'18px'} border='2px solid white' justifyContent='center' alignItems={'center'} borderRadius='30px'><FaDollarSign color='white' size={'10px'} /></Flex>}
              >
                <Flex>{t('navbar.myAccountNav.myPayments')}</Flex>
              </MenuItem>
            </Link>
            <MenuItem
              bg='#110434'
              color='#fff'
              fontSize='16px'
              alignItems={'center'}
              justifyContent={'center'}
              borderWidth={'1px'}
              borderColor={'#fff'}
              borderRadius='6px'
              _hover={{ borderWidth: '2px', fontWeight: 'bold' }}
              _active={{ borderWidth: '2px', fontWeight: 'bold', borderColor: '#FF00F6' }}
              marginTop='24px'
              lineHeight='26px'
              onClick={() => handleLogout()}
            >
              <Flex alignItems="center">
                <Logout2 />
                <Text ml="10px">{t('navbar.myAccountNav.logout')}</Text>
              </Flex>
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default MyAccountNav