import { Flex, Grid, GridItem, Text, Image } from '@chakra-ui/react';
import { useTranslation, Trans } from 'react-i18next';
import { cdn } from '@/utils/cloudflareCdn';

const Investors = () => {
    const { t } = useTranslation();
    const data = [
        'ygg',
        'galaxy_interactive',
        'bitkraft',
        'polygon',
        'arca',
        'infinity_ventures_crypto',
        'crypto_com_capital',
        'com2us',
        'morningstar_ventures',
        'the_venture_city',
        'menta_partners',
        'sangha_capital',
        'blocktower',
        'culture',
    ]

    return (
        <Flex justifyContent={'center'} alignItems='center' w='full' flexDirection={'column'}>
            <Text color={'white'} fontSize='20px' mb='24px'>{t('footer.titleInvestors')}</Text>

            <Grid gap='68px'
                rowGap={{
                    base: '16px',
                    md: '80px'
                }}
                templateColumns={{
                    base: 'repeat(2, 1fr)',
                    md: 'repeat(5, 1fr)',
                    lg: 'repeat(7, 1fr)',
                }}>
                {data.map((item, i) => (
                    <GridItem key={i}>
                        <Image loading='lazy' src={cdn(`/images/home/investors/${item}.png`)} />
                    </GridItem>
                ))}
            </Grid>
        </Flex>
    )
}

export default Investors