export const Gift = ({
  height = '24px',
  width = '24px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 3.5H9.3955C9.46883 3.25689 9.50408 3.0039 9.5 2.75C9.5 1.785 8.715 1 7.75 1C6.939 1 6.3975 1.741 6.048 2.5425C5.7035 1.785 5.1345 1 4.25 1C3.285 1 2.5 1.785 2.5 2.75C2.5 3.048 2.5395 3.2945 2.6045 3.5H2C1.4485 3.5 1 3.9485 1 4.5V5.5C1 6.0515 1.4485 6.5 2 6.5V10C2 10.5515 2.4485 11 3 11H9C9.5515 11 10 10.5515 10 10V6.5C10.5515 6.5 11 6.0515 11 5.5V4.5C11 3.9485 10.5515 3.5 10 3.5ZM7.75 2C8.1635 2 8.5 2.3365 8.5 2.75C8.5 3.5 8.187 3.5 8 3.5H6.761C7.0165 2.712 7.3875 2 7.75 2ZM3.5 2.75C3.5 2.3365 3.8365 2 4.25 2C4.694 2 5.107 2.7625 5.349 3.5H4C3.813 3.5 3.5 3.5 3.5 2.75ZM2 4.5H5.5V5.5H2V4.5ZM3 10V6.5H5.5V10H3ZM9 10H6.5V6.5H9V10ZM6.5 5.5V4.5425L6.5085 4.5H10L10.0005 5.5H6.5Z"
        fill={color}
      />
    </svg>


  )
}

export default Gift