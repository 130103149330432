// Ignore ts errors in all file
// @ts-nocheck

import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import { register, subscribe } from "./notification";

const firebaseConfig = {
  apiKey: "AIzaSyCUys7sqRKlckTusv_jfOaOihmJIW-BNM0",
  authDomain: "auth.olagg.io",
  databaseURL: "https://custom-cycling-344400-default-rtdb.firebaseio.com",
  projectId: "custom-cycling-344400",
  storageBucket: "custom-cycling-344400.appspot.com",
  messagingSenderId: "215801011338",
  appId: "1:215801011338:web:fd8c455fd62fa4a9666173",
  measurementId: "G-K67Q1G9QXJ"
};

function getOperatingSystemName() {
  let operatingSystem = "Unknown";

  if (typeof navigator !== "undefined" && navigator && navigator.userAgent) {
    if (navigator.userAgent.indexOf("X11") !== -1) {
      operatingSystem = "Unix";
    }
    if (navigator.userAgent.indexOf("Win") !== -1) {
      operatingSystem = "Windows";
    }
    if (navigator.userAgent.indexOf("Mac") !== -1) {
      operatingSystem = "macOS";
    }
    if (navigator.userAgent.indexOf("Linux") !== -1) {
      operatingSystem = "Linux";
    }
    if (navigator.userAgent.indexOf("Android") !== -1) {
      operatingSystem = "Android";
    }
    if (
      navigator.userAgent.indexOf("iPhone") !== -1 ||
      navigator.userAgent.indexOf("iPad") !== -1 ||
      navigator.userAgent.indexOf("iPod") !== -1
    ) {
      operatingSystem = "iOS";
    }
  }

  return operatingSystem;
}

const checkInApp = () => {
  if (navigator.userAgent.includes('FBAN') || navigator.userAgent.includes('FBAV') || navigator.userAgent.includes('Instagram')) {
    return true
  } else {
    return false
  }
}

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
app

export const auth = firebase.auth();

export const FCMSupport = await isSupported()
const messaging = FCMSupport ? getMessaging() : null

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });

export const requestForToken = (currentUserId: number) => {
  if (!FCMSupport) return
  if (!currentUserId) return;
  const currentToken = localStorage.getItem('fcmData') ? JSON.parse(localStorage.getItem('fcmData')!) : null;
  if (currentToken?.currentUserId === currentUserId) return;
  return getToken(messaging, { vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY })
    .then(async (currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        localStorage.setItem('fcmData', JSON.stringify({
          currentToken,
          currentUserId
        }))
        await register(currentToken)
        await subscribe('all')
      } else {
        rejectNotifications(currentUserId)
        console.log('No registration token available. Request permission to generate one.');
      }
    }
    ).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });
googleProvider.addScope('https://www.googleapis.com/auth/userinfo.email')
googleProvider.addScope('https://www.googleapis.com/auth/userinfo.profile')

const facebookProvider = new firebase.auth.FacebookAuthProvider();
facebookProvider.setCustomParameters({ prompt: 'select_account' });

const discordProvider = new firebase.auth.GoogleAuthProvider();
discordProvider.setCustomParameters({ prompt: 'select_account' });

const twitterProvider = new firebase.auth.TwitterAuthProvider();
twitterProvider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogleProvider = () => auth.signInWithPopup(googleProvider);

export const signInWithFacebookProvider = () => auth.signInWithPopup(facebookProvider);

export const signInWithDiscordProvider = () => auth.signInWithPopup(discordProvider);
// https://us-central1-olagg-front-development.cloudfunctions.net/api

export const signInWithTwitterProvider = () => auth.signInWithPopup(twitterProvider);

export const signInWithRedirectResult = () => auth.getRedirectResult();
