import { Flex, Text, Image } from "@chakra-ui/react";

interface ItemProps {
  image?: string;
  selected?: boolean;
  text: string;
  height?: string
}

const OpportunityItem = ({
  image,
  selected = false,
  text,
  height = '68px'
}: ItemProps) => {

  return (

    <>
      <Flex overflow={'hidden'}
        sx={{
          borderRadius: '16px',
          border: '1px solid #0000',
          background: 
            selected ? 'linear-gradient(45deg, #F8AC33 0%, #912FD4 100%) padding-box, transparent border-box'
             : 'linear-gradient(45deg, #0C0324 0%, #0C0324 100%) padding-box, linear-gradient(45deg, #F8AC33 0%, #912FD4 100%) border-box',
        }}
        position='relative'
        gap={'8px'}
        p='16px'
        h={height}
        alignItems='center'
      >
        <Image borderRadius={'8px'} width={'36px'} height={'36px'} objectFit={'cover'} src={image ? image : '/images/onboarding/platform_no_image.png'} />
        <Text fontSize={'16px'} fontWeight='700' color={'white'}>{text}</Text>
      </Flex>

    </>
  )
}

export default OpportunityItem;
