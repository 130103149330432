export const UpArrow = ({
  height = '24px',
  width = '24px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      {...props}
    >
      <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.112982 5.57965C0.148705 5.61546 0.191143 5.64387 0.237865 5.66326C0.284586 5.68265 0.334673 5.69263 0.385258 5.69263C0.435842 5.69263 0.485929 5.68265 0.532651 5.66326C0.579373 5.64387 0.62181 5.61546 0.657534 5.57965L5.0001 1.23631L9.34267 5.57965C9.37842 5.6154 9.42087 5.64376 9.46759 5.66311C9.51431 5.68247 9.56438 5.69243 9.61494 5.69243C9.66551 5.69243 9.71558 5.68247 9.7623 5.66311C9.80902 5.64376 9.85146 5.6154 9.88722 5.57965C9.92298 5.54389 9.95134 5.50144 9.97069 5.45472C9.99004 5.40801 10 5.35794 10 5.30737C10 5.2568 9.99004 5.20673 9.97069 5.16001C9.95134 5.1133 9.92298 5.07085 9.88722 5.03509L5.27238 0.42025C5.23665 0.384436 5.19422 0.356022 5.14749 0.336635C5.10077 0.317248 5.05068 0.307268 5.0001 0.307268C4.94952 0.307268 4.89943 0.317248 4.85271 0.336635C4.80599 0.356022 4.76355 0.384436 4.72783 0.42025L0.112982 5.03509C0.0771683 5.07082 0.0487541 5.11325 0.0293668 5.15998C0.00997949 5.2067 0 5.25679 0 5.30737C0 5.35795 0.00997949 5.40804 0.0293668 5.45476C0.0487541 5.50148 0.0771683 5.54392 0.112982 5.57965Z"
      fill={color}
      />
    </svg>
  )
}

export default UpArrow