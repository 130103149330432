import { Flex, Text, forwardRef } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const Charco = forwardRef(({
  on = true
}: {
  on?: boolean
}, ref) => {
  const { t } = useTranslation();

  const CharcoOn = (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill="url(#paint0_linear_6384_13001)"/>
      <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="url(#paint1_linear_6384_13001)"/>
      <path d="M15.2553 8.86224C15.2238 8.82183 15.1847 8.7894 15.1407 8.76716C15.0967 8.74491 15.0488 8.7334 15.0003 8.7334C14.9519 8.7334 14.904 8.74491 14.86 8.76716C14.816 8.7894 14.7769 8.82183 14.7453 8.86224C13.8596 9.99954 10.9717 13.9314 10.9717 16.8439C10.9717 19.558 12.5283 21.2666 15.0002 21.2666C17.4722 21.2666 19.0287 19.558 19.0287 16.8439C19.0287 13.9314 16.1408 9.99954 15.2553 8.86224ZM15.4478 19.6695C15.3941 19.6696 15.341 19.6556 15.2932 19.6285C15.2455 19.6015 15.2043 19.5622 15.1732 19.5141C15.1421 19.4659 15.122 19.4103 15.1146 19.3518C15.1072 19.2933 15.1127 19.2338 15.1306 19.1781C15.1535 19.1055 15.1966 19.0427 15.254 18.9985C15.3113 18.9544 15.3798 18.9313 15.4498 18.9324C15.8941 18.9314 16.32 18.7371 16.6342 18.3921C16.9485 18.0472 17.1254 17.5796 17.1264 17.0918C17.1253 17.0149 17.1464 16.9397 17.1866 16.8768C17.2268 16.8139 17.284 16.7665 17.3502 16.7413C17.4009 16.7217 17.4551 16.7156 17.5084 16.7238C17.5616 16.7319 17.6123 16.754 17.6562 16.7881C17.7001 16.8223 17.7358 16.8675 17.7605 16.9199C17.7851 16.9724 17.7979 17.0306 17.7978 17.0896C17.7971 17.7736 17.5493 18.4294 17.1088 18.9131C16.6682 19.3967 16.0709 19.6688 15.4478 19.6695Z" fill="#FAF5FF"/>
      <defs>
        <linearGradient id="paint0_linear_6384_13001" x1="0" y1="30" x2="30" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E10FF"/>
          <stop offset="1" stopColor="#AB17DF"/>
        </linearGradient>
        <linearGradient id="paint1_linear_6384_13001" x1="0" y1="30" x2="30" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E10FF"/>
          <stop offset="1" stopColor="#AB17DF"/>
        </linearGradient>
      </defs>
    </svg>
  );

  const CharcoOff = CharcoOn;

  return (
    <Flex ref={ref} zIndex='2' justifyContent='center' flexDirection='column' alignItems='center'>
      {on ? CharcoOn : CharcoOff}
      <Text
        fontSize='0.625rem' textAlign='center'
        fontWeight={on ? '600' : '400'}
        color={on ? 'black' : 'gray.600'}
      >
        {t('TokenProgressBar.labels.puddle')}
      </Text>
    </Flex>
  )
})
