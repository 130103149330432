import { Flex, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react"
import { DownArrow, Globe, UpArrow } from "@olagg/ui-kit"
import { useNavigate } from "react-router-dom"

import { useTranslation } from 'react-i18next'

const LanguageMenu = ({ closeMenu }) => {
  const languages = [
    { name: 'Español', value: 'es' },
    { name: 'English', value: 'en' },
    { name: 'Portugués', value: 'pt' }
  ]

  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const activeLanguage = languages.find(lang => lang.value === i18n.language)

  const changeLang = (newLang) => {
    const currentPath = window.location.pathname.split('/').slice(2).join('/');
    const searchParams = location.search;
    i18n.changeLanguage(newLang);
    localStorage.setItem('i18n_lang', newLang);
    navigate(`/${newLang}/${currentPath}${searchParams}`, { replace: true });
  }

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            isActive={isOpen}
            px='16px'
            borderRadius='6px'
            color='#fff'
            fontSize='16px'
            fontWeight={isOpen ? "bold" : "400"}
            position={'relative'}
          >
            <Flex alignItems="center">
              <Globe style={{ marginRight: '6px' }} />
              {activeLanguage?.name}
              {
                isOpen ? (
                  <UpArrow style={{ marginLeft: '10px' }} />
                ) : (
                  <DownArrow style={{ marginLeft: '10px' }} />
                )
              }
            </Flex>
          </MenuButton>
          <MenuList
            bg='#0C0324'
            borderWidth='0px'
            width={'100vw'}
            paddingLeft='24px'
          >
            <Flex
              border={'1px solid var(--gray-600, #4A5568)'}
              width={'85%'}
              maxWidth={'321px'}
              justifyContent={'space-evenly'}
              borderRadius={'6px'}
            >
              {languages.map((item, index) => (
                <MenuItem
                  bg='#0C0324'
                  color={'#fff'}
                  width={'auto'}
                  fontWeight={i18n.language == item.value ? 'bold' : '300'}
                  key={index}
                  onClick={() => {
                    changeLang(item.value)
                    closeMenu()
                  }}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Flex>
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default LanguageMenu