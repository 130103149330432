import { supabase } from "./supabaseClient";

const signInWithTwitterProvider = () => supabase.auth.signInWithOAuth({
  provider: 'twitter',
  options: { skipBrowserRedirect: true }
});

const signInWithDiscordProvider = (redirectTo?: string) => supabase.auth.signInWithOAuth({
    provider: 'discord',
    ...(redirectTo && { options: { redirectTo } })
  });

export {
  signInWithDiscordProvider,
  signInWithTwitterProvider
}
