import { useAuthStore } from '@olagg/store'

const useWeb3 = () => {
  
  const { me } = useAuthStore()
  
  const web3Status = () => {
    if (import.meta.env.VITE_WEB3_USER_ENABLED == 'all') return true
  
    const users = (import.meta.env.VITE_WEB3_USER_ENABLED).split(',')
    return (users != '') ? users.includes(me?.id.toString()) : false
  }

  return {
    web3Status
  }
}

export default useWeb3;
