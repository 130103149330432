import { DeviceEndpoints, SegmentEndpoints } from "../api-hooks/endpoints/endpoints"
import { fetcher } from "../api-hooks/fetcher"
import { analytics } from "../../apps/app/src/analytics"

type StoredToken = {
  currentToken: string,
  currentUserId: number
}


export const register = async (token: string) => {
  await fetcher(DeviceEndpoints.subscribe({
    token,
    platform: "web"
  }))
}

export const subscribe = async (segment: string) => {
  const token: StoredToken | null = localStorage.getItem('fcmData') ? JSON.parse(localStorage.getItem('fcmData')!) : null;
  
  if (!token) return;
  await fetcher(SegmentEndpoints.assignDevice({ token: token.currentToken, segment })).then(r => {
    if(r.created_at == r.updated_at) {
      analytics()?.identify(token.currentUserId.toString(), {
        push_notifications_enabled: true
      });
    }
  })
    .catch(err => console.log(err))
}

export const rejectNotifications = async (currentUserId: number) => {
  analytics()?.identify(currentUserId.toString(), {
    push_notifications_enabled: false
  });
}
