import { Link as ReactLink, LinkProps } from 'react-router-dom';
import { ReactNode } from 'react';

/**
 * Link
 * 
 * Extends the Link component from react-router-dom, allowing for external urls
 * 
 * i.e.: 
 *  - <Link to='/novedades'>Ir a Novedades</Link> will redirect to /novedades
 *  - <Link to='https://www.google.com'>Abrir Google</Link> will render an <a> with href='https://www.google.com'
 * 
 */

interface ILink extends LinkProps {
  children: ReactNode;
} 

const Link = ({
  children,
  ...props
}: ILink) => {
  if (props.to.toString().startsWith('http'))
    return <a href={props.to.toString()} {...props}>{children}</a>
  else
    return <ReactLink {...props}>{children}</ReactLink>
}

export default Link;
