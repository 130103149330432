export const Cart = ({
  height = '16',
  width = '18',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 16"
    fill="none">
      <path
      fillRule="evenodd" clipRule="evenodd" d="M0.00146484 0.757324C0.00146484 0.343111 0.337251 0.00732422 0.751465 0.00732422H2.15974C2.50389 0.00732422 2.80388 0.241547 2.88734 0.575423L3.34887 2.42151H17.2484C17.4793 2.42151 17.6974 2.52791 17.8395 2.70995C17.9817 2.89198 18.032 3.12935 17.976 3.35341L15.9642 11.4007C15.8807 11.7346 15.5807 11.9688 15.2366 11.9688H4.7751C4.43095 11.9688 4.13096 11.7346 4.0475 11.4007L2.03568 3.35341L1.57416 1.50732H0.751465C0.337251 1.50732 0.00146484 1.17154 0.00146484 0.757324ZM3.72387 3.92151L5.36068 10.4688H14.651L16.2878 3.92151H3.72387ZM4.77534 13.5783C4.52289 13.5783 4.31825 13.7829 4.31825 14.0354C4.31825 14.2878 4.52289 14.4924 4.77534 14.4924C5.02778 14.4924 5.23243 14.2878 5.23243 14.0354C5.23243 13.7829 5.02778 13.5783 4.77534 13.5783ZM2.81825 14.0354C2.81825 12.9545 3.69447 12.0783 4.77534 12.0783C5.85621 12.0783 6.73243 12.9545 6.73243 14.0354C6.73243 15.1162 5.85621 15.9924 4.77534 15.9924C3.69447 15.9924 2.81825 15.1162 2.81825 14.0354ZM14.7796 14.0354C14.7796 13.7829 14.9842 13.5783 15.2367 13.5783C15.4891 13.5783 15.6938 13.7829 15.6938 14.0354C15.6938 14.2878 15.4891 14.4924 15.2367 14.4924C14.9842 14.4924 14.7796 14.2878 14.7796 14.0354ZM15.2367 12.0783C14.1558 12.0783 13.2796 12.9545 13.2796 14.0354C13.2796 15.1162 14.1558 15.9924 15.2367 15.9924C16.3176 15.9924 17.1938 15.1162 17.1938 14.0354C17.1938 12.9545 16.3176 12.0783 15.2367 12.0783Z"
      fill={color}/>
    </svg>
  )
}

export default Cart