import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text,
    Flex,
    Button,
    Input,
    Show,
    useToast,
} from '@chakra-ui/react'
import { ShareSocial } from 'react-share-social'
import { FaRegCopy } from 'react-icons/fa'

interface IShareModal {
    title: string;
    description?: string;
    link: string;
    isOpen: boolean;
    onClose: () => void;
}

const style = {
    root: {
        background: 'none',
        padding: 0,
        margin: 0,
        border: 0,
        width: '100%'
    },
    copyContainer: {
        display: 'none',
        background: 'black'
    },
};

export default function ShareModal({ 
    title,
    description,
    link,
    isOpen,
    onClose
}: IShareModal) {
    const toast = useToast();

    const handleCopy = () => {
        navigator.clipboard.writeText(link);
        toast({
            title: `Enlace copiado`,
            status: 'success',
            position: 'bottom-right',
            duration: 2000,
            isClosable: false
        });
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size='xl'>
            <ModalOverlay />
            <ModalContent mx={5}>
                <ModalCloseButton />
                <ModalBody textAlign={'center'} py={8}>
                    <Text fontSize='1.5rem' fontWeight="600" color="gray.800" textAlign='left' mb='2'>
                        {title}
                    </Text>
                    <Text fontSize='1rem' color="gray.500" textAlign='left'>
                        {description}
                    </Text>
                    <Flex justifyContent={'center'}>
                        <ShareSocial
                            url={link}
                            socialTypes={['facebook', 'twitter', 'whatsapp', 'telegram']}
                            style={style}
                        />
                    </Flex>
                    <Flex alignItems='center'>
                        <Input isReadOnly value={link} color='gray.800' fontWeight='600' mr='2' h='48px' />
                        <Button variant='filledGradient' leftIcon={<Flex ml='2'><FaRegCopy /></Flex>} px='5' onClick={handleCopy}>
                            <Show above='sm'><Text mr='2'>Copiar enlance</Text></Show>
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
