export const ScholarshipIcon = ({
  height = '24px',
  width = '24px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.3754 20C11.1843 20 10.9979 19.9763 10.816 19.9289C10.635 19.8808 10.4585 19.809 10.2865 19.7135L4.55587 16.6189C4.17383 16.4088 3.87775 16.1272 3.66762 15.7742C3.4575 15.4204 3.35244 15.0239 3.35244 14.5845V9.08309L0.601719 7.56447C0.391595 7.44986 0.238777 7.30659 0.143266 7.13467C0.0477555 6.96275 0 6.77173 0 6.5616C0 6.35148 0.0477555 6.16046 0.143266 5.98854C0.238777 5.81662 0.391595 5.67335 0.601719 5.55874L10.2865 0.286533C10.4585 0.191022 10.635 0.119197 10.816 0.0710599C10.9979 0.0236865 11.1843 0 11.3754 0C11.5664 0 11.7528 0.0236865 11.9347 0.0710599C12.1158 0.119197 12.2923 0.191022 12.4642 0.286533L23.3811 6.24642C23.5721 6.34193 23.7203 6.48023 23.8258 6.66132C23.9305 6.84317 23.9828 7.03916 23.9828 7.24928V14.5845C23.9828 14.9093 23.8728 15.1813 23.6527 15.4006C23.4334 15.6206 23.1614 15.7307 22.8367 15.7307C22.5119 15.7307 22.2399 15.6206 22.0206 15.4006C21.8006 15.1813 21.6905 14.9093 21.6905 14.5845V7.82235L19.3983 9.08309V14.5845C19.3983 15.0239 19.2932 15.4204 19.0831 15.7742C18.873 16.1272 18.5769 16.4088 18.1948 16.6189L12.4642 19.7135C12.2923 19.809 12.1158 19.8808 11.9347 19.9289C11.7528 19.9763 11.5664 20 11.3754 20ZM11.3754 10.8023L19.2264 6.5616L11.3754 2.32092L3.52436 6.5616L11.3754 10.8023ZM11.3754 17.7077L17.106 14.6132V10.2865L12.4928 12.8367C12.3209 12.9322 12.1394 13.0036 11.9484 13.051C11.7574 13.0991 11.5664 13.1232 11.3754 13.1232C11.1843 13.1232 10.9933 13.0991 10.8023 13.051C10.6113 13.0036 10.4298 12.9322 10.2579 12.8367L5.6447 10.2865V14.6132L11.3754 17.7077Z"
        fill={color}
      />
    </svg>
  )
}

export default ScholarshipIcon
