import { STEPS } from "../constants";
import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, Tooltip, useToast, Image, Button, useDisclosure, Grid, GridItem, Box, ModalFooter } from "@chakra-ui/react";

import { ROUTES } from "../../../routes";
import { analytics } from "../../../analytics";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuthStore, useOnboardingStore } from "@olagg/store";
import { AdTrackingContext } from '@/contexts/adTrackingContext';
import { useTranslation } from "react-i18next";
import { UserPreferencesEndpoints, fetcher } from "@olagg/api-hooks";
import Rocket from '/images/onboarding/rocket.png'

const OBCompletedModal = () => {
  const { fcb, fbp } = useContext(AdTrackingContext)
  const { t, i18n } = useTranslation();
  const { isOnboardingOpen, step, setStep, closeOnboarding, obXp } = useOnboardingStore()

  const { pathname } = useLocation()
  const isLoginPath = pathname.includes(ROUTES.LOGIN)

  return (
    <Modal size={'xl'}
      isOpen={isOnboardingOpen && step === STEPS.completedModal}
      onClose={closeOnboarding}
      blockScrollOnMount={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={'24px'} overflowY={{ base: 'auto', md: 'hidden' }} maxH={{ base: '70vh', md: 'auto' }}>
          <Flex alignItems={'center'} justifyContent='center' w='full' flexDirection='column'>

            <Flex position={'relative'} justifyContent='center'>
              <Flex mt={'60px'}>
                <Image src={Rocket} maxW='150px' my={'20px'} />
              </Flex>
            </Flex>
            {true ? (
              // {obXp > 0 ? (
              <>
                <Text color="black" fontSize={'18px'} fontWeight='700' lineHeight={'120%'} textAlign={'center'}>{t('OnBoarding.OBCompletedModal.title')}</Text>
                <Text color="#718096" fontSize={'14px'} fontWeight='400' mt={'16px'} lineHeight={'120%'} textAlign={'center'}
                  sx={{
                    '& a': {
                      color: '#4E10FF'
                    }
                  }}
                  dangerouslySetInnerHTML={
                    { __html: t('OnBoarding.OBCompletedModal.lead', { link: `/${i18n.language}/quests`, 'interpolation': { 'escapeValue': false } }) }
                  }
                />
              </>
            ) : (
              <>
                <Text color="black" fontSize={'18px'} fontWeight='700' lineHeight={'120%'} textAlign={'center'}>{t('OnBoarding.OBNotCompletedModal.title')}</Text>
              </>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex w={'100%'} justifyContent={'center'} sx={{
            ' a': {
              width: '100%'
            }
          }}>
            <Button onClick={closeOnboarding} as='span' variant={'filledGradient'} fontSize={'14px'} fontWeight='500' w={'100%'}>
              {t('OnBoarding.Common.continue')}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>

  )
}

export default OBCompletedModal;
