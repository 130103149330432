export const Cup = ({
  height = '24px',
  width = '24px',
  color = 'white',
  fillOpacity = '0.48',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      x="0px" y="0px"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.668,9.992C28.258,9.361,27.525,9,26.655,9h-2.831l0.413-4.908c0.026-0.28-0.11-0.558-0.3-0.766S23.437,3,23.155,3H16
            H9.22C8.938,3,8.627,3.118,8.438,3.326s-0.326,0.485-0.3,0.766L8.551,9H5.72C4.851,9,4.117,9.361,3.707,9.992
            c-0.41,0.63-0.442,1.447-0.089,2.241l2.375,5.346C6.597,18.937,8.234,20,9.72,20h1.331C12.216,21.521,14,22.59,15,22.899V28h-4.28
            c-0.552,0-1,0.447-1,1s0.448,1,1,1H16h5.655c0.552,0,1-0.447,1-1s-0.448-1-1-1H17v-5.101C18,22.59,20.159,22,21.324,20h1.331
            c1.486,0,3.123-1.063,3.727-2.421l2.375-5.346C29.11,11.439,29.077,10.622,28.668,9.992z M9.72,18c-0.707,0-1.612-0.588-1.899-1.233
            l-2.375-5.346c-0.095-0.212-0.072-0.323-0.062-0.339C5.395,11.065,5.488,11,5.72,11h3l0.362,4.299
            c0.136,0.952,0.406,1.862,0.78,2.701H9.72z M21.308,15.065C20.849,18.282,18,21,16,21s-4.661-2.718-5.121-5.935L10.316,5H16h6.059
            L21.308,15.065z M26.929,11.421l-2.375,5.346C24.267,17.412,23.362,18,22.655,18h-0.141c0.374-0.839,0.644-1.749,0.78-2.701
            L23.656,11h3c0.232,0,0.325,0.065,0.335,0.082C27.001,11.098,27.024,11.209,26.929,11.421z"

        fillOpacity={fillOpacity}
        fill={color}
      />
    </svg>
  )
}

export default Cup
