export const NewsIcon = ({
  height = '19px',
  width = '20px',
  color = 'white'
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd" clipRule="evenodd" d="M16.5924 6.60765C16.6787 6.69716 16.7484 6.80134 16.7982 6.9153C16.8112 6.94318 16.8201 6.97189 16.8292 7.00141C16.8304 7.00524 16.8316 7.00909 16.8328 7.01295C16.8632 7.10067 16.8812 7.1922 16.8864 7.2849C16.8873 7.29553 16.8903 7.3055 16.8932 7.31531C16.8966 7.32681 16.9 7.3381 16.9 7.35V18.9C16.9 20.0581 15.9582 21 14.8 21H2.20001C1.04186 21 0.100006 20.0581 0.100006 18.9V2.1C0.100006 0.94185 1.04186 0 2.20001 0H9.55001C9.56207 0 9.57351 0.00345775 9.585 0.00693342C9.59452 0.00981008 9.60407 0.012699 9.61406 0.01365C9.70669 0.0192929 9.79815 0.0373025 9.88601 0.0671999C9.89647 0.0707984 9.90694 0.0740911 9.91735 0.0773638C9.94034 0.0845955 9.96302 0.0917297 9.9847 0.10185C10.0987 0.151632 10.2028 0.221321 10.2924 0.30765L16.5924 6.60765ZM10.6 6.3H13.3153L10.6 3.5847V6.3ZM2.20001 2.1V18.9H14.8021L14.8 8.4H9.55001C9.27153 8.4 9.00446 8.28937 8.80754 8.09246C8.61063 7.89555 8.50001 7.62848 8.50001 7.35V2.1H2.20001ZM13.6157 10.244H2.85962V12.449H13.6157V10.244ZM13.6157 14.654H2.85962V16.859H13.6157V14.654ZM5.54864 5.83396H2.85962V8.03896H5.54864V5.83396Z" fill={color}
      />
    </svg>

  )
}

export default NewsIcon
