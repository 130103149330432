import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useMediaQuery
} from '@chakra-ui/react'

import { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '../../routes'

interface IProps {
  isOpen: boolean
  closeButton?: boolean
  onClose?: () => void
  overlay?: boolean
  modalHeader?: ReactNode
  size?: {}
  children: ReactNode
  colorScheme?: string
  color?: string
  isMobile?: boolean
}

export default function ({
  isOpen = false,
  closeButton = true,
  onClose,
  overlay = true,
  modalHeader,
  size = { base: 'xs', md: 'lg' },
  colorScheme = 'white',
  color = 'black',
  children,
  isMobile = false
}: IProps) {
  const [isLargerThan768] = useMediaQuery('(min-height: 768px)')

  const { pathname } = useLocation()
  const isLoginPath = pathname.includes(ROUTES.LOGIN)
  
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={isMobile ? 'full' : size} scrollBehavior='outside' closeOnEsc={!isLoginPath} closeOnOverlayClick={!isLoginPath}>
      {overlay && <ModalOverlay backdropFilter='blur(10px)' />}
      <ModalContent backgroundColor={colorScheme} maxH={isLargerThan768 ? '800px' : '600px'} maxW="800px" mx={isMobile ? 0 : 4}>
        {closeButton && !isLoginPath && <ModalCloseButton bg='transparent' borderRadius="50%" right="10px" zIndex='2' color={color} />}
        {modalHeader && <ModalHeader p='0' position='relative'>{modalHeader}</ModalHeader>}
        <ModalBody padding={0}>{children}</ModalBody>
      </ModalContent>
    </Modal>
  )
}
