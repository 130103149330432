import { Flex, Text, forwardRef } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const Ola = forwardRef(({
  on = true
}: {
  on?: boolean
}, ref) => {
  const { t } = useTranslation();

  const OlaOn = (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6393_34829)">
        <rect width="30" height="30" rx="15" fill="white" fillOpacity="0.8"/>
        <g clipPath="url(#clip1_6393_34829)">
          <path d="M30.4972 30.0164H-6.94336V22.439C9.54268 22.439 5.58002 8.17337 20.3286 7.22712C20.8225 7.1954 21.0903 7.77055 20.7385 8.10631C19.551 9.23956 17.8906 11.4479 17.8906 14.9372C17.8906 18.6665 23.4451 22.439 30.4972 22.439V30.0164ZM24.0398 11.0034C24.5841 10.4784 23.6013 9.53321 22.6995 9.53321C22.6995 9.53321 23.4954 11.5282 24.0398 11.0034ZM20.1765 4.8703C19.677 3.62629 17.352 4.49754 16.6971 5.92689C16.6971 5.92689 20.6759 6.11432 20.1765 4.8703Z" fill="url(#paint0_radial_6393_34829)"/>
        </g>
      </g>
      <rect x="1" y="1" width="28" height="28" rx="14" stroke="url(#paint1_radial_6393_34829)" strokeWidth="2"/>
      <defs>
        <radialGradient id="paint0_radial_6393_34829" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11.7769 17.145) rotate(90) scale(12.8714 18.7203)">
          <stop stopColor="#FEAD10"/>
          <stop offset="1" stopColor="#B1893C"/>
        </radialGradient>
        <radialGradient id="paint1_radial_6393_34829" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15 15) rotate(90) scale(15)">
          <stop stopColor="#FEAD10"/>
          <stop offset="1" stopColor="#B1893C"/>
        </radialGradient>
        <clipPath id="clip0_6393_34829">
          <rect width="30" height="30" rx="15" fill="white"/>
        </clipPath>
        <clipPath id="clip1_6393_34829">
          <rect width="30.0332" height="28.9606" fill="white" transform="translate(-0.0166016 0.519775)"/>
        </clipPath>
      </defs>
    </svg>
  );

  const OlaOff = (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6384_12985)">
        <rect width="30" height="30" rx="15" fill="#E2E8F0"/>
        <g clipPath="url(#clip1_6384_12985)">
          <path d="M30.4972 30.0164H-6.94336V22.439C9.54268 22.439 5.58002 8.17337 20.3286 7.22712C20.8225 7.1954 21.0903 7.77055 20.7385 8.10631C19.551 9.23956 17.8906 11.4479 17.8906 14.9372C17.8906 18.6665 23.4451 22.439 30.4972 22.439V30.0164ZM24.0398 11.0034C24.5841 10.4784 23.6013 9.53321 22.6995 9.53321C22.6995 9.53321 23.4954 11.5282 24.0398 11.0034ZM20.1765 4.8703C19.677 3.62629 17.352 4.49754 16.6971 5.92689C16.6971 5.92689 20.6759 6.11432 20.1765 4.8703Z" fill="url(#paint0_radial_6384_12985)"/>
        </g>
      </g>
      <defs>
        <radialGradient id="paint0_radial_6384_12985" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11.7769 17.145) rotate(90) scale(12.8714 18.7203)">
          <stop stopColor="#FEAD10"/>
          <stop offset="1" stopColor="#B1893C"/>
        </radialGradient>
        <clipPath id="clip0_6384_12985">
          <rect width="30" height="30" rx="15" fill="white"/>
        </clipPath>
        <clipPath id="clip1_6384_12985">
          <rect width="30.0332" height="28.9606" fill="white" transform="translate(-0.0166016 0.519775)"/>
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <Flex ref={ref} zIndex='2' justifyContent='center' flexDirection='column' alignItems='center'>
      {on ? OlaOn : OlaOff}
      <Text
        fontSize='0.625rem' textAlign='center'
        fontWeight={on ? '600' : '400'}
        color={on ? 'black' : 'gray.600'}
      >
        {t('TokenProgressBar.labels.wave')}
      </Text>
    </Flex>
  )
})
