import * as React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from '@olagg/ui-kit'
import * as ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './polyfills'
import '@fontsource/inter'
import "./i18n"
import { AdTrackingProvider } from './contexts/adTrackingContext'

let customFonts = theme
customFonts.fonts.body = `'Inter', sans-serif`

/**
 * La carga de analytics la realiza el consent-manager y utiliza 
 * el snippet de index.html (no es compatible con analytics-next)
 */
// if (import.meta.env.PROD) {
//   import('./analytics')
//     .then(a => {
//       a.loadAnalytics()
//     })
//     .catch(e => {
//       console.error('error loading analytics lib', e)
//     })
// }

const rootElement = document.getElementById('root')!
ReactDOM.createRoot(rootElement).render(
  // <React.StrictMode>
  <ChakraProvider theme={customFonts}>
    <BrowserRouter>
      <AdTrackingProvider>
        <App />
      </AdTrackingProvider>
    </BrowserRouter>
  </ChakraProvider>
  // </React.StrictMode>
)
