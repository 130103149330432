import { Center, Spinner } from '@chakra-ui/react'
import { FC } from 'react'

const Loading: FC = () => {
  return (
    <Center h="90vh">
      <Spinner color="white" size="xl" />
    </Center>
  )
}

export default Loading
