import { Box, CloseButton, Flex, Text } from '@chakra-ui/react';
import { IoIosCheckmarkCircle, IoIosCloseCircle, IoIosWarning, IoMdInformationCircle } from "react-icons/io";
import { useEffect, useState } from 'react';

import { Alert as AlertType } from '@olagg/db-types';
import Link from '../../utils/link';
import { useAlert } from './hooks/useAlert';

export const Alert = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { data } = useAlert({});
  const alert = data?.alert;

  useEffect(() => {
    setIsOpen(true);
  }, [alert]);

  if (!alert) return null;
  if (!isOpen) return null;

  const get = (alert?: AlertType) => {
    switch (alert?.type) {
      default:
      case 'information':
        return {
          icon: <IoMdInformationCircle color='#3182CE' />,
          style: {
            backgroundColor: '#BEE3F8',
            border: '1px solid #3182CE'
          }
        };  
      case 'confirmation':
        return {
          icon: <IoIosCheckmarkCircle color='#68D391' />,
          style: {
            backgroundColor: '#F0FFF4',
            border: '1px solid #68D391'
          }
        };
      case 'warning':
        return {
          icon: <IoIosWarning color='#F6AD55' />,
          style: {
            backgroundColor: '#FFFAF0',
            border: '1px solid #F6AD55'
          }
        };
      case 'urgent':
        return {
          icon: <IoIosCloseCircle color='#FC8181' />,
          style: {
            backgroundColor: '#FFF5F5',
            border: '1px solid #FC8181'
          }
        };
    }
  }

  return (
    <Flex w='full' h='52px' justifyContent='center' alignItems='center' backgroundImage={alert.image} backgroundRepeat='no-repeat'
      style={get(alert).style}
    >
      <Flex 
        w='full' justifyContent='space-between' px='2' fontSize='0.875rem' 
        maxW={import.meta.env.VITE_LAYOUT_MAXWIDTH}
      >
        <Flex alignItems='center' flexWrap='wrap' overflow='hidden'>
          <Flex mr='1' overflow='hidden'>
            <Box fontSize='1.3rem'>{get(alert).icon}</Box>
            <Text ml='1' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'><b>{alert.title}</b></Text>
          </Flex>
          <Text ml='1' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>{alert.message}</Text>
        </Flex>
        <Flex alignItems='center'>
          {alert.ctaLabel && (
            <Link to={alert.ctaUrl}>
              <Box border='1px solid black' borderRadius='5' py='1' px='5' ml='2' whiteSpace='nowrap' color='#0C0324' fontWeight='600'>
                {alert.ctaLabel}
              </Box>
            </Link>
          )}
          {alert.closable && (
            <CloseButton size='sm' color='gray.500' ml='2' onClick={() => setIsOpen(false)} />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
