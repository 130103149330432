import { Endpoint, Method } from './constants';

const API_KEY_NEWSROOM = import.meta.env.VITE_NEWSROOM_API_KEY;

class NewsroomEndpoint {
  room: string;
  config: string;

  constructor(room: string) {
    this.room = room;
    this.config = `?api_key=${API_KEY_NEWSROOM}`;
  }

  byCategory = (category: string): Endpoint => ({
    method: Method.GET,
    path: `/${this.room}/by_category${this.config}&category=${category}`
  })

  bySlug = (slug: string, force: boolean = false): Endpoint => ({
    method: Method.GET,
    path: `/${this.room}/by_slug${this.config}&slug=${slug}&force=${force}`
  })

  byTag = (tag: string[], match: 'all' | 'any' | 'exclude' = 'any'): Endpoint => ({
    method: Method.GET,
    path: `/${this.room}/by_tag${this.config}&tag=${tag.join(',')}&match=${match}&limit=100`
  })

  byTitle = (title: string): Endpoint => ({
    method: Method.GET,
    path: `/${this.room}/by_title${this.config}&title=${title}`
  })

  lastEvents = (): Endpoint => ({
    method: Method.GET,
    path: `/${this.room}/last${this.config}&limit=100`
  })

  last = (limit?: number): Endpoint => ({
    method: Method.GET,
    path: `/news/last?limit=${limit || 100}`
  })

  getAll = (limit?: number): Endpoint => ({
    method: Method.GET,
    path: `/${this.room}/last${this.config}&limit=${limit || 500000}`
  })

  getWithOffset = (page: number): Endpoint => ({
    method: Method.GET,
    path: `/news/last?page=${page}`
  })

  search = (query: string, page: number): Endpoint => ({
    method: Method.GET,
    path: query ? `/news/search?q=${query}&limit=30&offset=0` : this.getWithOffset(page).path
  })
}

export const NewsEndpoints = new NewsroomEndpoint('novedades');
export const EventsEndpoints = new NewsroomEndpoint('eventos');
export const BannersEndpoints = new NewsroomEndpoint('banners');
export const PostsEndpoints = new NewsroomEndpoint('landings');
export const GuideEndpoints = new NewsroomEndpoint('guias');
export const NewsroomGamesEndpoints = new NewsroomEndpoint('juegos');
export const NewsroomGamesGuidesEndpoints = new NewsroomEndpoint('guias');
export const NewsroomOpportunitiesEndpoints = new NewsroomEndpoint('oportunidades');

export enum BannersSectionTypes {
  HOME = 'home',
  RANKING = 'ranking',
  TOURNAMENT = 'tournament',
}

export enum BannersDeviceTypes {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}
