export const LogoOlaGG = ({
    width = '141px',
    height = '49px',
    color = 'white',
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width={width}
            height={height}
            viewBox="0 0 141 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M84.0979 17.618C83.9974 16.5552 84.9703 15.6088 86.0331 15.7198C89.1104 15.7251 92.1877 15.6881 95.2651 15.741C96.4706 15.6987 97.047 16.9254 97.0258 17.967C97.0787 22.9901 97.0311 28.0133 97.047 33.0364C95.503 33.0364 93.9643 33.0364 92.4204 33.0364C92.4204 31.6246 92.4204 30.2182 92.4204 28.8064C91.1408 28.8064 89.8665 28.8064 88.5869 28.8064C88.5869 30.2182 88.5869 31.6246 88.5869 33.0364C87.0906 33.0364 85.5889 33.0364 84.0926 33.0364C84.0926 27.897 84.082 22.7575 84.0979 17.618ZM88.5605 19.6009C88.6028 21.388 88.5869 23.1805 88.5869 24.973C89.8612 24.973 91.1408 24.973 92.4204 24.973C92.2353 23.1382 92.817 21.0761 92.1243 19.3788C90.9399 19.2202 89.692 19.1408 88.5605 19.6009Z" fill="white" />
            <path d="M108.182 17.4913C108.193 16.3968 109.303 15.5984 110.35 15.72C113.887 15.7094 117.43 15.72 120.973 15.72C120.973 16.9996 120.973 18.2739 120.973 19.5534C118.239 19.5534 115.511 19.5534 112.777 19.5534C112.788 22.7683 112.745 25.9883 112.803 29.2032C114.104 29.2032 115.41 29.2032 116.716 29.2032C116.737 28.188 116.743 27.178 116.753 26.1681C115.949 26.1628 115.156 26.1628 114.363 26.1628C114.363 24.8833 114.363 23.609 114.363 22.3294C117.007 22.3294 119.651 22.3294 122.294 22.3294C122.294 23.609 122.295 24.8885 122.284 26.1734C121.924 26.1681 121.205 26.1628 120.846 26.1575C120.84 28.0663 120.825 29.9698 120.862 31.8786C119.936 33.3909 118.107 33.0207 116.6 33.0419C114.31 32.9943 112.021 33.1159 109.742 32.9943C108.695 32.9203 108.055 31.8046 108.156 30.837C108.161 26.3849 108.113 21.9381 108.182 17.4913Z" fill="white" />
            <path d="M53.2984 15.9842C54.0492 15.5136 54.9746 15.7833 55.81 15.7092C58.4432 15.7621 61.0816 15.6511 63.7148 15.7515C64.6771 15.6828 65.3751 16.6081 65.3116 17.507C65.3275 22.1124 65.3275 26.7231 65.3169 31.3338C65.3804 32.1851 64.582 32.9994 63.7412 32.9994C60.5529 33.084 57.3645 33.0258 54.1814 33.0311C53.2667 33.0523 52.3467 32.1957 52.3837 31.2704C52.3308 26.8817 52.389 22.4878 52.3572 18.0992C52.2726 17.2955 52.5317 16.386 53.2984 15.9842ZM56.9944 19.5533C57.005 22.7628 56.9785 25.9776 57.0103 29.1871C58.274 29.203 59.5377 29.203 60.8014 29.203C60.849 25.9934 60.8173 22.7839 60.8225 19.5744C59.5483 19.548 58.2687 19.5533 56.9944 19.5533Z" fill="white" />
            <path d="M70.2123 33.0365C70.2176 27.2625 70.207 21.4886 70.2176 15.7146C71.7562 15.7199 73.3002 15.7199 74.8388 15.7199C74.8388 20.2143 74.8388 24.7087 74.8388 29.203C76.6472 29.203 78.4502 29.203 80.2585 29.203C80.2585 30.4826 80.2585 31.7569 80.2585 33.0365C76.9116 33.0365 73.5593 33.0365 70.2123 33.0365Z" fill="white" />
            <path d="M126.413 17.5812C126.371 16.5977 127.291 15.6512 128.285 15.7253C131.923 15.6935 135.561 15.7464 139.193 15.7041C139.214 16.989 139.214 18.2685 139.214 19.5534C136.481 19.5534 133.752 19.5534 131.019 19.5534C131.019 22.7682 131.019 25.9883 131.019 29.2031C132.33 29.2031 133.647 29.2137 134.963 29.1555C134.979 28.1615 134.984 27.1621 134.99 26.1628C134.191 26.1628 133.398 26.1628 132.605 26.1628C132.605 24.8832 132.605 23.6089 132.605 22.3293C135.249 22.3293 137.893 22.3293 140.536 22.3293C140.536 23.6089 140.536 24.8832 140.536 26.1575C140.171 26.1628 139.442 26.1628 139.077 26.1681C139.05 28.061 139.172 29.9645 139.013 31.8574C138.643 32.4867 138.051 33.1159 137.253 33.0313C134.255 33.0418 131.251 33.063 128.248 33.0154C127.053 33.0894 126.228 31.8786 126.397 30.7629C126.397 26.369 126.36 21.9751 126.413 17.5812Z" fill="white" />
            <mask id="mask0_5467_4783" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="1" width="45" height="47">
                <path d="M38.8037 9.17578H6.81558H0.711548L3.76731 14.4204L19.7576 41.8912L22.8134 47.1358L25.8692 41.8912L41.8595 14.4204L44.9152 9.17578H38.8037ZM22.8134 40.1455L6.81558 12.6746H38.8037L22.8134 40.1455Z" fill="white" />
                <path d="M22.8138 1.49463L5.05591 11.6643V31.9963L22.8138 42.1661L40.5717 31.9963V11.6643L22.8138 1.49463ZM37.044 29.9832L24.5738 37.1221L22.8138 40.1529L21.0537 37.1221L8.58352 29.9832V15.6981L6.82346 12.6672H10.3511L22.8213 5.52834L35.2915 12.6672H38.8191L37.059 15.6981V29.9832H37.044Z" fill="white" />
                <path d="M22.8146 30.1913L19.5866 24.657H15.4073L22.8146 37.3821L28.9036 26.9078H24.7244L22.8146 30.1913Z" fill="white" />
                <path d="M26.6188 13.9746L20.7319 24.0849L22.814 27.6655L25.9896 22.2055H27.4576L25.383 25.7786H29.5622L36.4377 13.9746H26.6188ZM27.6074 19.4346L28.7758 17.4215H30.2437L29.0754 19.4346H27.6074Z" fill="white" />
                <path d="M25.1433 13.9746H9.19043L14.7552 23.5278H19.5935L25.1433 13.9746ZM17.429 20.0512H16.9047L15.3769 17.4215H18.9569L17.429 20.0512Z" fill="white" />
            </mask>
            <g mask="url(#mask0_5467_4783)">
                <path d="M47.2365 -0.526123H-1.58081V47.8933H47.2365V-0.526123Z" fill="url(#paint0_linear_5467_4783)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_5467_4783" x1="41.5833" y1="3.52083" x2="7.91637" y2="40.3129" gradientUnits="userSpaceOnUse">
                    <stop offset="0.0982117" stopColor="#FFAD4B" />
                    <stop offset="0.1522" stopColor="#FFA355" />
                    <stop offset="0.2455" stopColor="#FF8771" />
                    <stop offset="0.3668" stopColor="#FF599E" />
                    <stop offset="0.51" stopColor="#FF1BDC" />
                    <stop offset="0.5668" stopColor="#FF00F6" />
                    <stop offset="0.6236" stopColor="#FB00F6" />
                    <stop offset="0.6817" stopColor="#EF00F6" />
                    <stop offset="0.7405" stopColor="#DA00F6" />
                    <stop offset="0.7998" stopColor="#BD00F7" />
                    <stop offset="0.8595" stopColor="#9800F7" />
                    <stop offset="0.9195" stopColor="#6B01F8" />
                    <stop offset="0.9786" stopColor="#3601F9" />
                    <stop offset="1" stopColor="#2101F9" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default LogoOlaGG
