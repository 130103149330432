import { STEPS } from "../constants";
import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, Tooltip, useToast, Image, Button, useDisclosure } from "@chakra-ui/react";

import { ROUTES } from "../../../routes";
import { analytics } from "../../../analytics";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthStore, useOnboardingStore } from "@olagg/store";
import { AdTrackingContext } from '@/contexts/adTrackingContext';
import { useTranslation } from "react-i18next";
import Stepper from "./Stepper";
import ConfettiExplosion from "react-confetti-explosion";
import avatar from '@olagg/ui-kit/svg/avatar_circle.svg'
import { FaPencil } from "react-icons/fa6";
import ModalEditProfile from "@/modules/Profile/components/ModalEditProfile";
import IconCommunities from '../assets/communities.svg'
import IconEmojiEvents from '../assets/emoji_events.svg'
import IconSportsEsports from '../assets/sports_esports.svg'

const WelcomeProfileEdit = () => {
  const { fcb, fbp } = useContext(AdTrackingContext)
  const { t } = useTranslation();
  const { isOnboardingOpen, step, setStep, closeOnboarding, getObQuests } = useOnboardingStore()
  const { me } = useAuthStore()
  const [isLoading, setIsLoading] = useState(true)
  const [imgAvatar, setImgAvatar] = useState(avatar)

  useEffect(() => {
    setImgAvatar(me?.avatar || avatar)
    setIsLoading(false)
  }, [me])

  const { pathname } = useLocation()
  const isLoginPath = pathname.includes(ROUTES.LOGIN)

  const { onClose, onOpen, isOpen } = useDisclosure()

  const [isConfettiActive, setIsConfettiActive] = useState(true)

  useEffect(() => {
    getObQuests()
    setTimeout(() => setIsConfettiActive(false), 2000)
  }, [])

  return (
    <Modal
      isOpen={isOnboardingOpen && step === STEPS.welcomeProfileEdit}
      onClose={closeOnboarding}
      isCentered
      size={'full'}
      scrollBehavior='outside'
      closeOnEsc={!isLoginPath}
      closeOnOverlayClick={!isLoginPath}
    >
      <ModalOverlay backdropFilter='blur(10px)' />
      <ModalContent bg='transparent'
        height={'100%'}
        width={'100%'}
        overflow={{ base: 'auto', md: 'hidden' }}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <ModalBody
          padding={0}
          sx={{
            backgroundColor: '#0C0324',
            background: 'url(/images/bg_modal.png)',
            backgroundSize: 'cover'
          }}
          m='0'
          display={'flex'}
          flexDirection={'row'}
          w='100%'
          h='100%'
          overflow={isConfettiActive ? 'hidden' : 'hidden'}
          maxW={{ base: '100%', '2xl': '1280' }}
          maxH={{ base: '100%', '2xl': '720px' }}
        >
          <Flex flex='1' alignItems={'center'} p={{ base: '16px', md: '32px' }} flexDirection={'column'} position='relative'>
            {!isLoginPath && <ModalCloseButton bg='transparent' borderRadius="50%" top='10px' right="10px" zIndex='2' color={'white'} />}

            <Flex flex={'0 0 auto'} flexDirection='column' alignItems={'center'}>
              <Stepper currentStep={1} totalSteps={4} />
              <Flex mt={'32px'} position='relative' flexDirection={'column'} alignItems='center' gap='8px'>
                <Flex
                  width='120px'
                  minWidth='120px'
                  height='120px'
                  minHeight='120px'
                  borderRadius={'50%'}
                  position='relative'
                  zIndex={1}
                >
                  <Image
                    loading='lazy'
                    fallbackSrc={avatar}
                    width='100%'
                    height='100%'
                    src={imgAvatar}
                    objectFit={'cover'}
                    borderRadius='50%'
                  />
                </Flex>
                <Flex flexDirection={'column'} alignItems='center'>

                  <Flex alignItems={'center'}>
                    <Text color='white' fontSize={'18px'} fontWeight='700'>
                      {me?.name || 'Alias'}
                    </Text>
                    <Button onClick={onOpen} variant='ghost'
                      sx={{
                        padding: 0,
                        position: 'absolute',
                        justifyContent: 'center',
                        alignItems: 'center',
                        right: '-45px',
                        '&:hover': {
                          cursor: 'pointer',
                          background: 'none'
                        }
                      }}
                    >
                      <FaPencil size='14px' color='white' />
                    </Button>
                  </Flex>

                  <Text color={'white'} fontSize='14px'>{me?.email}</Text>
                </Flex>
                {isConfettiActive && (
                  <Flex position={'absolute'} zIndex={0} left='60px' top='60px'>
                    <ConfettiExplosion duration={2000} />
                  </Flex>
                )}
              </Flex>

              <Flex flexDirection={'column'} alignItems='center' mt='24px' maxW={'780px'}>
                <Text color='white' fontSize={{ base: '20px', md: '30px' }} fontWeight='700' textAlign={'center'}>
                  {t('OnBoarding.WelcomeProfileEdit.title')}
                </Text>
              </Flex>
            </Flex>
            <Flex flex={'1 1 auto'} overflowY='auto'>
              <Flex flexDirection={'column'} alignItems='center' my='16px' maxW={'780px'} gap='24px'>
                <Flex gap='16px'>
                  <Image h={{ base: '24px', md: '32px' }} w={{ base: '24px', md: '32px' }} src={IconCommunities} position='relative' />
                  <Text as='span' color='white' fontSize={{ base: '14px', md: '20px' }} fontWeight='400' lineHeight='130%'>
                    {t('OnBoarding.WelcomeProfileEdit.listText1')}
                  </Text>
                </Flex>
                <Flex gap='16px'>
                  <Image h={{ base: '24px', md: '32px' }} w={{ base: '24px', md: '32px' }} src={IconSportsEsports} position='relative' />
                  <Text as='span' color='white' fontSize={{ base: '14px', md: '20px' }} fontWeight='400' lineHeight='130%'>
                    {t('OnBoarding.WelcomeProfileEdit.listText2')}
                  </Text>
                </Flex>
                <Flex gap='16px'>
                  <Image h={{ base: '24px', md: '32px' }} w={{ base: '24px', md: '32px' }} src={IconEmojiEvents} position='relative' />
                  <Text as='span' color='white' fontSize={{ base: '14px', md: '20px' }} fontWeight='400' lineHeight='130%'>
                    {t('OnBoarding.WelcomeProfileEdit.listText3')}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex flex={'0 0 auto'} width='100%' mt='16px'>
              <Flex mt={'auto'} width='100%' flexDirection={'column'} alignItems='center' gap='8px'>
                <Button
                  type='submit'
                  width={'100%'}
                  variant={'filledGradient'}
                  fontSize={'14px'}
                  maxW='360px'
                  onClick={() => setStep(STEPS.platformsPreferences)}
                >
                  {t('OnBoarding.WelcomeProfileEdit.moveForward')}
                </Button>
                <Button
                  type='submit'
                  width={'100%'}
                  variant='unstyled'
                  fontSize={'14px'}
                  maxW='360px'
                  color={'white'}
                  onClick={() => closeOnboarding()}
                  mb={{ base: '16px', md: '0' }}
                >
                  {t('OnBoarding.WelcomeProfileEdit.doItLater')}
                </Button>
              </Flex>
            </Flex>
          </Flex>

        </ModalBody>
        <ModalEditProfile onClose={onClose} isOpen={isOpen} />
      </ModalContent>
    </Modal>
  )
}

export default WelcomeProfileEdit;
