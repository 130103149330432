export const FilledBook = ({
  height = '24px',
  width = '24px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3926 0.51001H3.39258C2.18658 0.51001 0.392578 1.30901 0.392578 3.51001V17.51C0.392578 19.711 2.18658 20.51 3.39258 20.51H18.3926V18.51H3.40458C2.94258 18.498 2.39258 18.315 2.39258 17.51C2.39258 17.409 2.40158 17.319 2.41658 17.237C2.52858 16.662 2.99958 16.52 3.40358 16.51H17.3926C17.4106 16.51 17.4236 16.501 17.4416 16.5H18.3926V2.51001C18.3926 1.40701 17.4956 0.51001 16.3926 0.51001ZM16.3926 14.51H2.39258V3.51001C2.39258 2.70401 2.94258 2.52201 3.39258 2.51001H10.3926V9.51001L12.3926 8.51001L14.3926 9.51001V2.51001H16.3926V14.51Z"
        fill={color}
      />
    </svg>
  )
}

export default FilledBook
