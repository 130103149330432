export const Google = ({
  height = '19',
  width = '10',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M2.3512 5.65751C3.01681 4.33211 4.0379 3.21792 5.30035 2.43949C6.56279 1.66105 8.01685 1.24904 9.5 1.24951C11.2624 1.24951 12.7944 1.77927 14.0514 2.67798C14.5118 3.00712 14.5201 3.66555 14.12 4.06578L13.2496 4.93642C12.8654 5.32081 12.2494 5.31447 11.7823 5.03655C11.107 4.63474 10.3338 4.43111 9.5 4.43111C7.416 4.43111 5.652 5.83911 5.024 7.72951C4.864 8.20951 4.7728 8.72151 4.7728 9.24951C4.7728 9.77751 4.864 10.2895 5.024 10.7695C5.6528 12.6607 7.416 14.0679 9.5 14.0679C10.576 14.0679 11.492 13.7839 12.2088 13.3039C12.6243 13.0303 12.9801 12.6753 13.2546 12.2603C13.3078 12.1798 13.3577 12.0974 13.4044 12.0133C13.7067 11.4674 13.2311 10.8895 12.6071 10.8895H10.5C9.94772 10.8895 9.5 10.4418 9.5 9.88951V8.79511C9.5 8.24283 9.94772 7.79511 10.5 7.79511H16.1987C16.6825 7.79511 17.1029 8.14122 17.145 8.62318C17.168 8.88737 17.18 9.15672 17.18 9.43111C17.18 11.8679 16.308 13.9191 14.7944 15.3111C13.4712 16.5335 11.66 17.2495 9.5 17.2495C8.44931 17.2499 7.40883 17.0433 6.43804 16.6414C5.46724 16.2395 4.58516 15.6503 3.84221 14.9073C3.09926 14.1644 2.51 13.2823 2.10811 12.3115C1.70622 11.3407 1.49958 10.3002 1.5 9.24951C1.5 7.95831 1.8088 6.73751 2.3512 5.65751Z" />
      <defs>
        <linearGradient id="paint0_linear_1685_24023" x1="1.5" y1="17.2495" x2="17.18" y2="17.2495" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E10FF" />
          <stop offset="1" stopColor="#AB17DF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Google
