export const DownArrow = ({
  height = '24px',
  width = '24px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.112982 0.420111C0.148705 0.384297 0.191143 0.355883 0.237865 0.336495C0.284586 0.317108 0.334673 0.307129 0.385258 0.307129C0.435842 0.307129 0.485929 0.317108 0.532651 0.336495C0.579373 0.355883 0.62181 0.384297 0.657534 0.420111L5.0001 4.76345L9.34267 0.420111C9.37842 0.384355 9.42087 0.355992 9.46759 0.336641C9.51431 0.31729 9.56438 0.30733 9.61494 0.30733C9.66551 0.30733 9.71558 0.31729 9.7623 0.336641C9.80902 0.355992 9.85146 0.384355 9.88722 0.420111C9.92298 0.455866 9.95134 0.498315 9.97069 0.545032C9.99004 0.591749 10 0.64182 10 0.692387C10 0.742953 9.99004 0.793024 9.97069 0.839741C9.95134 0.886458 9.92298 0.928907 9.88722 0.964662L5.27238 5.57951C5.23665 5.61532 5.19422 5.64373 5.14749 5.66312C5.10077 5.68251 5.05068 5.69249 5.0001 5.69249C4.94952 5.69249 4.89943 5.68251 4.85271 5.66312C4.80599 5.64373 4.76355 5.61532 4.72783 5.57951L0.112982 0.964662C0.0771683 0.928939 0.0487541 0.886501 0.0293668 0.83978C0.00997949 0.793058 0 0.742971 0 0.692387C0 0.641802 0.00997949 0.591715 0.0293668 0.544993C0.0487541 0.498272 0.0771683 0.455834 0.112982 0.420111Z"
        fill={color}
      />
    </svg>
  )
}

export default DownArrow