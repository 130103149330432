export const Globe = ({
  height = '24px',
  width = '24px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.00016 1.58337C4.32416 1.58337 1.3335 4.57404 1.3335 8.25004C1.3335 11.926 4.32416 14.9167 8.00016 14.9167C11.6762 14.9167 14.6668 11.926 14.6668 8.25004C14.6668 4.57404 11.6762 1.58337 8.00016 1.58337ZM13.2875 7.58337H11.4448C11.3626 6.1242 10.9544 4.70202 10.2502 3.42137C11.0663 3.80274 11.7727 4.38431 12.3038 5.11198C12.8348 5.83966 13.1732 6.68981 13.2875 7.58337ZM8.3535 2.93471C9.0435 3.84404 9.9715 5.45471 10.1048 7.58337H6.02016C6.11283 5.85271 6.68283 4.23137 7.65416 2.93404C7.76883 2.92737 7.8835 2.91671 8.00016 2.91671C8.1195 2.91671 8.23616 2.92737 8.3535 2.93471ZM5.79216 3.40137C5.13616 4.66204 4.7575 6.09137 4.68683 7.58337H2.71283C2.82801 6.68193 3.17125 5.82477 3.71009 5.09298C4.24893 4.36119 4.96555 3.77897 5.79216 3.40137ZM2.71283 8.91671H4.6955C4.78616 10.5027 5.13883 11.902 5.73283 13.07C4.92096 12.6873 4.21865 12.1062 3.6908 11.3802C3.16296 10.6543 2.8266 9.80702 2.71283 8.91671ZM7.6335 13.5654C6.6995 12.4334 6.14816 10.8474 6.0275 8.91671H10.1028C9.96416 10.7654 9.35816 12.3807 8.3675 13.5647C8.24616 13.5727 8.12483 13.5834 8.00016 13.5834C7.87616 13.5834 7.7555 13.5727 7.6335 13.5654ZM10.3075 13.0507C10.9442 11.8547 11.3328 10.45 11.4348 8.91671H13.2868C13.1743 9.79956 12.8428 10.6403 12.3224 11.3623C11.8021 12.0843 11.1094 12.6647 10.3075 13.0507Z"
        fill={color}
      />
    </svg>


  )
}

export default Globe