import { FC } from 'react';
import { FormControl, FormLabel, FormErrorMessage, Input, Text, Checkbox, Switch, Textarea, ChakraProps } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';

/**
 * Customized input component for use in OlaGG forms
 *
 * Implements React Hook Forms and validations
 *
 * @param {string} controlName - Name of the input control, as used by React Hook Form
 * @param {string} label - Label to be displayed above the input control
 * @param {string} description - Optional - Description to be displayed below the label
 * @param {string} inputType - Optional - Type of the html input control to be rendered, defaults to text
 * @param {boolean} visible - Optional - Whether the input control should be visible, defaults to true
 * @param {boolean} required - Optional - Whether the input control is required, defaults to {visible}
 * @param {Object} datePickerProps - Optional - Additional style props to be applied to the date picker in case of input type `date` or `datetime-local`
 * @param {string} placeholder - Optional - Placeholder text to be displayed in the input control
 * @param {function} onChange - Optional - Callback function to be called when the input control value changes
 * @param {Object} customStyles - Optional - Additional styles (any CSS props) to be applied to the input control
 * @param {Object} chakraStyles - Optional - Additional styles (Chakra UI props) to be applied to the input control
 *
 * @example
 *
 * const methods = useForm();
 *
 * const onSubmit = (dataForm: any) => {
 *   console.log(dataForm);
 * };
 *
 * <OlaggForm methods={methods} onSubmit={onSubmit} style={{width: '100%'}}>
 *   <OlaggFormInput
 *     label='Text to show here'
 *     controlName='inputNameHere'
 *     description='Description of the required data to be entered in the input'
 *     required={true}
 *     errors={errors}
 *     register={register}
 *     control={control}
 *   />
 *
 *   <Button type='submit'>Submit</Button>
 * </OlaggForm>
 *
 */

interface IOlaGGFormInputProps {
  controlName: string,
  label?: string,
  description?: string,
  inputType?: string,
  visible?: boolean,
  required?: boolean,
  datePickerProps?: React.CSSProperties,
  placeholder?: string,
  onChange?: (e: React.ChangeEvent<any>) => void,
  customStyles?: React.CSSProperties,
  chakraStyles?: any,
  labelStyles?: any,
  [x: string]: any
};

const OlaGGFormInput: FC<IOlaGGFormInputProps> = ({
  controlName,
  label,
  description,
  inputType = 'text',
  visible = true,
  required = false,
  placeholder = '',
  datePickerProps = {},
  onChange = (e) => { },
  customStyles = {},
  chakraStyles = {},
  labelStyles = {},
  ...props
}) => {
  const { register, control, formState: { errors } } = useFormContext();

  const inputProps = (field: any) => ({
    mt: '2',
    mb: '5',
    placeholder: placeholder,
    sx: customStyles,
    ...chakraStyles,
    onChange: (e: React.ChangeEvent<any>) => {
      field.onChange(e);
      onChange(e);
    },
    ...register(controlName, { required: required })
  });

  const datePickerStyle = {
    '::-webkit-calendar-picker-indicator': {
      cursor: 'pointer',
      borderRadius: '10%',
      ...datePickerProps
    }
  };

  const caseEmpty = (value: any) => {
    if (controlName == 'tokenId' && value == 0) {
      return 0
    } else {
      return ''
    }
  }

  return (
    <FormControl
      id={controlName}
      isRequired={required}
      isInvalid={!!errors[controlName]}
      display={visible ? 'initial' : 'none'}
    >
      {label && <FormLabel sx={labelStyles} htmlFor={controlName} mb='1' m='0' lineHeight='normal'>{label}</FormLabel>}
      {description && (<Text color='gray.400' fontSize='sm'>{description}</Text>)}
      <FormErrorMessage>
        {errors[controlName] && errors[controlName]?.message?.toString()}
      </FormErrorMessage>

      <Controller
        name={controlName}
        control={control}
        render={({ field }) => (
          inputType === 'checkbox' ?
            <Checkbox isChecked={field.value} {...field} {...inputProps(field)} {...props} /> :
            inputType === 'switch' ?
              <Switch isChecked={field.value} {...field} {...inputProps(field)} {...props} /> :
              inputType === 'date' ?
                <Input type='date' {...field} value={field.value || ''} {...inputProps(field)} sx={datePickerStyle} {...props} /> :
                inputType === 'datetime' ?
                  <Input type='datetime-local' {...field} value={field.value || ''} {...inputProps(field)} sx={datePickerStyle} {...props} /> :
                  inputType === 'textarea' ?
                    <Textarea {...field} value={field.value || ''} {...inputProps(field)} {...props} /> :
                    // defaults to text input
                    <Input {...field} value={field.value || caseEmpty(field.value)} {...inputProps(field)} name={controlName} {...props} />
          // ... can implement other controls here
        )}
      />
    </FormControl>
  )
};

export default OlaGGFormInput;
