import { Flex, Text, Image } from "@chakra-ui/react";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { cdn } from '@/utils/cloudflareCdn';

interface ItemProps {
  image?: string;
  selected?: boolean;
  text: string;
  width?: string | {}
  height?: string | {}
  noBorder?: boolean;
}

const GameCategoryItem = ({
  image,
  selected = false,
  text,
  width = 'inherit',
  height = '100%',
  noBorder = false,
}: ItemProps) => {

  return (

    <>
      <Flex overflow={'hidden'}
        sx={{
          borderRadius: '16px',
          border: selected && !noBorder ? '2px solid #0000' : '2px solid transparent',
          background: selected && !noBorder ? 'linear-gradient(220deg, #F8AC33 0%, #912FD4 150%) border-box' : 'transparent'
        }}
        position='relative'
        height={height}
        width={width}
      >
        <Image width={'100%'} objectFit={'cover'} src={image ? cdn(image, { h: '800px' }) : '/images/onboarding/platform_no_image.png'} />
        <Flex w='100%' h='100%' position='absolute' alignItems={'flex-end'} pb='10px' px={'10px'} background='linear-gradient(0deg, #20023c 10%, transparent 80%)' />
        <Flex w='100%' h='100%' position='absolute' alignItems={'flex-end'} pb='10px' px={'10px'}>
          <Text mt='8px' fontSize={'14px'} fontWeight='700'
          color={selected ? 'black' : 'white'}
          backgroundColor={selected ? 'white' : 'rgba(255,255,255, 0.3)'}
          padding='0px 8px 1px 8px'
          borderRadius={'12px'}
          overflow='hidden'
          maxWidth='130px'
          noOfLines={1}
          textOverflow='ellipsis'
          mr='8px'
          >
            {text}
          </Text>
          <Flex
            marginLeft={'auto'}
            backgroundColor={selected ? 'white' : 'rgba(255,255,255, 0.3)'}
            width='26px'
            height='26px'
            minWidth='26px'
            maxHeight='26px'
            justifyContent={'center'}
            alignItems={'center'}
            borderRadius='50%'
          >
            {selected ? <FaHeart color='#c26b87' fontSize={'12px'} /> : <FaRegHeart color='white' fontSize={'12px'} />}
          </Flex>
        </Flex>
      </Flex>

    </>
  )
}

export default GameCategoryItem;
