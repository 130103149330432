import { Flex, Grid, GridItem, Text, Image } from '@chakra-ui/react';
import { useTranslation, Trans } from 'react-i18next';
import { cdn } from '@/utils/cloudflareCdn';

const Social = () => {
    const { t } = useTranslation();
    const data = [
        {name: 'discord', link: 'https://discord.gg/olagg'},
        {name: 'twitter', link: 'https://twitter.com/OLAGuildGames?s=18'},
        {name: 'tiktok', link: 'https://www.tiktok.com/@olaguildgames?is_from_webapp=1&sender_device=pc'},
        {name: 'youtube', link: 'https://www.youtube.com/@OlaGuildGames'},
        {name: 'twitch', link: 'https://www.twitch.tv/olaguildgames'},
        {name: 'instagram', link: 'https://instagram.com/olaguildgames?utm_source=web&utm_medium=header-sobre-ola&utm_campaign=website'},
        {name: 'facebook', link: 'https://facebook.com/olaguildgames?utm_source=web&utm_medium=header-sobre-ola&utm_campaign=website'},
    ]

    return (
        <Flex justifyContent={'center'} alignItems='center' w='full' flexDirection={'column'}>
            <Text color={'white'} fontSize='20px' mb='24px'>{t('footer.titleSocial')}</Text>

            <Grid gap={{
                base: '36px',
                md: '80px'
            }}
            rowGap={{
                base: '16px',
                md: '80px'
            }}
            justifyContent='center'
            alignItems='center'
            templateColumns={{
                base: 'repeat(4, 1fr)',
                md: 'repeat(7, 1fr)',
            }}>
                {data.map((item, i) => (
                    <GridItem key={i}>
                        <a href={item.link}>
                            <Image loading='lazy' src={cdn(`/images/home/social/${item.name}.png`)} />
                        </a>
                    </GridItem>
                ))}
            </Grid>
        </Flex>
    )
}

export default Social