import { Flex, Box, Center, Button, Text } from "@chakra-ui/react"
import { useOnboardingStore } from "@olagg/store"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowRight } from "react-icons/fa"
import { useSearchParams } from "react-router-dom"
import Modal from "../../../components/Modal/Modal"
import { STEPS } from "../constants"
import useAuth from "../hooks/useAuth"

const LinkDiscord = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true)
  const { isOnboardingOpen, step, closeOnboarding, forceDiscord } = useOnboardingStore()
  const { loginWithProvider } = useAuth()
  const redirect = useRef<any>()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (!forceDiscord) {
      closeOnboarding()
      return
    }
    setIsLoading(false)

  }, [])

  const initRedirectPath = () => {
    redirect.current = searchParams.get('redirect') || localStorage.getItem('redirect')
    if (redirect.current) {
      localStorage.setItem('redirect', redirect.current)
    }
  }

  const handleLogin = () => {
    localStorage.setItem('redirect', window.location.pathname)
    initRedirectPath()
    loginWithProvider('discord')
  }

  return (
    <Modal isOpen={isOnboardingOpen && step === STEPS.linkDiscord} onClose={closeOnboarding} size={{ base: 'xs', md: 'lg' }}>
      <Box p="8">
        <Text fontSize='26px' fontWeight='600' mb={4} color="#3F2091">{t('Login.linkYourDiscordAccount')}</Text>

        <Center w="full" flexDir="column">
          <Flex gap="4" direction="column" w="full">

            <Text fontSize="18px" fontWeight="600" color="black.800" textAlign="left">
              {t('Login.linkDiscordLead')}
            </Text>
            <Button
              variant="filledGradient"

              rightIcon={<FaArrowRight size={22} />}
              alignItems="center"
              fontSize="18px"
              fontWeight="medium"
              onClick={handleLogin}
              isLoading={isLoading}
            >
              {t('Login.linkDiscord')}
            </Button>
          </Flex>
        </Center>

      </Box>
    </Modal >
  )
}

export default LinkDiscord;
