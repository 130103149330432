import { useAuthStore, useOnboardingStore } from "@olagg/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalEditProfile from "../../Profile/components/ModalEditProfile";
import { STEPS } from "../constants";
import useAuth from '../hooks/useAuth';

const UpdateProfile = () => {
  const { validateReferFriend } = useAuth();
  const { isOnboardingOpen, step, closeOnboarding, forceDiscord, setStep, isNewAccount } = useOnboardingStore();
  const { t } = useTranslation();
  const { me } = useAuthStore();

  useEffect(() => {
    // si llegamos aca podemos asegurar que se trata de una cuenta nueva?
    validateReferFriend(true)
  }, []);

  useEffect(() => {
    if (me?.name && isOnboardingOpen && step === STEPS.completeData) {
      if (forceDiscord && !me?.discordId) {
        setStep(STEPS.linkDiscord);
      } else {
        isNewAccount ? setStep(STEPS.welcomeProfileEdit) : closeOnboarding();
      }
    } else {
      isNewAccount ? setStep(STEPS.welcomeProfileEdit) : closeOnboarding();
    }
  }, [step])

  return (
    // <ModalEditProfile
    //   isOpen={isOnboardingOpen && step === STEPS.completeData}
    //   onClose={closeOnboarding}
    //   title={t('Login.UpdateProfile.customizeAccount')}
    //   subtitle={t('Login.UpdateProfile.customizeAccountLead')}
    // />
    <></>
  )
}

export default UpdateProfile;
