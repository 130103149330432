import type {
  ApplicationStatus,
  ApplicationStatusUpdate,
  CreateTournamentParams,
  Device,
  FilterTournamentsParams,
  Game,
  GameCategory,
  GameCredentials,
  InscriptionData,
  MarketPlaceItem,
  Notification,
  QuestOwner,
  ScholarshipApplication,
  Segment,
  TokenUserIdType,
  Tos,
  UpdateTournamentData,
  User,
  UserAgreement,
  UserContent,
  UserWallet
} from '@olagg/db-types'
import { CreateTokenData, ListTokenData, UpdateTokenData } from '@olagg/web3/dist/domain/types/token.type'
import { Endpoint, Method } from './constants'

import { IGetAllProps } from '../interfaces/IUseGames'
import { IUseCredentials } from '../interfaces/IUseCredentials'
import { IUseNews } from '../interfaces/IUseNews'
import { IUseNotifications } from '../interfaces/IUseNotifications'
import { IUseQuestOwners } from '../interfaces/IUseQuestOwners'
import { IUseScholarships } from '../interfaces/IUseScholarships'
import { IUseStoryCategories } from '../interfaces/IUseStoryCategories'
import { IUseTournaments } from '../interfaces/IUseTournaments'
import { IUseTreasuresGame } from '../interfaces/IUseTreasuresGame'
import { IUseUsers } from '../interfaces/IUseUsers'
import { ParentType } from '../queries/useRanking';

const parameterize = (params: object) => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    if (value) {
      return `${acc}&${key}=${value}`
    }
    return acc
  }, '')
}

const LoginWithDiscordEndpoint: Endpoint = {
  method: Method.GET,
  path: '/login/discord'
}


const LoginWithGoogleEndpoint: Endpoint = {
  method: Method.GET,
  path: '/login/google'
}

const LoginWithTwitterEndpoint: Endpoint = {
  method: Method.GET,
  path: '/login/twitter'
}

const LogoutEndpoint: Endpoint = {
  method: Method.GET,
  path: '/auth/logout'
}

const MeEndpoint: Endpoint = {
  method: Method.GET,
  path: '/users/me'
}

const AuthCheckEndpoint: Endpoint = {
  method: Method.GET,
  path: '/auth/check'
}

export const AuthEndpoints = {
  login: {
    discord: LoginWithDiscordEndpoint,
    google: LoginWithGoogleEndpoint,
    twitter: LoginWithTwitterEndpoint
  },
  logout: LogoutEndpoint,
  me: MeEndpoint,
  check: AuthCheckEndpoint
}

const GetGamesEndpoint: Endpoint = {
  method: Method.GET,
  path: '/games'
}

const GetVisibleGamesEndpoint: Endpoint = {
  method: Method.GET,
  path: '/games/visible'
}

export const GetVisibleGamesWithFiltersEndpoint = (filters: string) => {
  return {
    method: Method.GET,
    path: `/games/visible${filters}`
  } as Endpoint
}

export const GetQuestsEndpoint: Endpoint = {
  method: Method.GET,
  path: '/quests/with_user_quests'
}

export const GetCompletedUserQuestsEndpoint: Endpoint = {
  method: Method.GET,
  path: '/user_quests/completed_user_quests'
}

export const GetQuestsOwnersEndpoint: Endpoint = {
  method: Method.GET,
  path: '/owners'
}

export const GetQuestsByOwnerIdEndpoint = (ownerId: string) => {
  return {
    method: Method.GET,
    path: `/quests?ownerId=${ownerId}`
  } as Endpoint
}

export const GetOwnerByGameIdEndpoint = (gameId: string) => {
  return {
    method: Method.GET,
    path: `/quests/owners?gameId=${gameId}`
  } as Endpoint
}

export const ClaimQuest = (questId: string) => {
  return {
    method: Method.PATCH,
    path: `/quests/${questId}/claim`
  } as Endpoint
}

export const CompleteQuest = (questId: string) => {
  return {
    method: Method.PUT,
    path: `/user_quests/${questId}/complete`
  } as Endpoint
}

export const GetUserQuest = (questId: string) => {
  return {
    method: Method.GET,
    path: `/user_quests/${questId}`
  } as Endpoint
}

const GetGamesCategories: Endpoint = {
  method: Method.GET,
  path: '/games/categories'
}
const GetGamesDashboard: Endpoint = {
  method: Method.GET,
  path: '/games/incomplete-applications'
}

const GetGamesRating: Endpoint = {
  method: Method.GET,
  path: '/games?filter=["rating"]&order=desc&limit=10'
}

const GetGamesFeatured: Endpoint = {
  method: Method.GET,
  path: '/games?category=Destacada%20OLA&limit=10'
}

const GetGamesLast10: Endpoint = {
  method: Method.GET,
  path: '/games?filter=["createdAt"]&order=desc&limit=10'
}

export const LoginWithProviderEndpoint = {
  google: (data: Partial<any>) =>
  ({
    method: Method.POST,
    path: `/oauth`,
    body: data
  } as Endpoint<Partial<any>>),
  facebook: (data: Partial<any>) =>
  ({
    method: Method.POST,
    path: '/auth/facebook',
    body: data
  } as Endpoint<Partial<any>>),
  discord: (data: Partial<any>) =>
  ({
    method: Method.POST,
    path: '/oauth',
    body: data
  } as Endpoint<Partial<any>>),
  newsroom: (data: Partial<any>) =>
  ({
    method: Method.POST,
    path: '/oauth',
    body: data
  } as Endpoint<Partial<any>>),
  unlink: (data: Partial<any>) =>
  ({
    method: Method.DELETE,
    path: '/unlink',
    body: data
  } as Endpoint<Partial<any>>)
}

export const ConnectWithProviderEndpoint = {
  twitter: (data: Partial<any>) =>
  ({
    method: Method.POST,
    path: `/oauth`,
    body: data
  } as Endpoint<Partial<any>>),
  discord: (data: Partial<any>) =>
  ({
    method: Method.POST,
    path: '/auth/discord',
    body: data
  } as Endpoint<Partial<any>>)
}

export const PublicProfileEndpoints = {
  getProfile: (userId: string) => ({
    method: Method.GET,
    path: `/users/${userId}`
  }),
  getStats: (userId: string, slug: string | null) => ({
    method: Method.GET,
    path: slug == null ? `/users/${userId}/stats` : `/users/${userId}/stats?game_slug=${slug}`
  }),

}

export const GameEndpoints = {
  bySlug: (slug: string) => ({
    method: Method.GET,
    path: `/games/${slug}`
  }),
  all: ({ page, limit }: IGetAllProps) => ({
    method: Method.GET,
    path: `/games?page=${page}&limit=${limit}`
  }),
  visible: GetVisibleGamesEndpoint,
  categories: GetGamesCategories,
  dashboard: GetGamesDashboard,
  rating: GetGamesRating,
  featured: GetGamesFeatured,
  last10: GetGamesLast10,
  new: (data: Partial<Game>) =>
  ({
    method: Method.POST,
    path: '/games',
    body: data
  } as Endpoint<Partial<Game>>),
  update: (slug: string, data: Partial<Game>) =>
  ({
    method: Method.PUT,
    path: `/games/${slug}`,
    body: data
  } as Endpoint<Partial<Game>>),
}

export const GameContentEndpoints = {
  bySlug: (slug: string) => ({
    method: Method.GET,
    path: `/games/${slug}`
  }),
  new: (data: any) =>
  ({
    method: Method.POST,
    path: '/games/game-content',
    body: data
  } as Endpoint),
  update: (id: string, data: any) =>
  ({
    method: Method.PUT,
    path: `/games/game-content/${id}`,
    body: data
  } as Endpoint),
  delete: (id: string) =>
  ({
    method: Method.DELETE,
    path: `/games/game-content/${id}`,
  } as Endpoint),
}

export const ScholarshipsEndpoints = {
  byId: (id: string) => ({
    method: Method.GET,
    path: `/scholarships/${id}`
  }),
  myScholarships: () => ({
    method: Method.GET,
    path: `/scholarships`
  }),
  myScholarship: (slug: string) =>
  ({
    method: Method.GET,
    path: `/scholarships/by_slug?slug=${slug}`
  } as Endpoint),
  apply: (slug: string) => ({
    method: Method.POST,
    path: `/scholarships`,
    body: {
      gameId: slug
    }
  }),
  cancel: (slug: string) => ({
    method: Method.DELETE as Method.DELETE,
    path: `/scholarships/${slug}`
  }),
  paginatedScholarships: ({
    slug,
    status,
    country,
    query,
    dateSort,
    offset,
    limit
  }: IUseScholarships) => ({
    method: Method.GET,
    path: `/scholarships?offset=${offset || 0}&limit=${limit || 100
      }&country=${country || ''}&query=${query || ''
      }&dateSort=${dateSort}&slug=${slug || ''}&status=${status || ''
      }`
  }),
  paginatedCredentials: ({
    slug,
    status,
    query,
    offset,
    limit
  }: IUseCredentials) => ({
    method: Method.GET,
    path: `/scholarships/credentials?slug=${slug || ''}&status=${status || ''
      }&query=${query || ''}&offset=${offset}&limit=${limit}`
  }),

  createCredentials: (
    slug: string,
    data: Omit<
      GameCredentials,
      'gameId' | 'scholarshipApplicationId'
    >,
    discordId?: string
  ) =>
  ({
    method: Method.POST,
    path: `/scholarships/${slug}/credentials?discordId=${discordId || ''
      }`,
    body: data
  } as Endpoint<GameCredentials>),
  createBulkCredentials: (formData: FormData) =>
  ({
    method: Method.POST,
    path: `/mass-operations/game-credentials`,
    body: formData,
    isForm: true
  } as Endpoint<any>), //TODO: type this properly
  updateMyCredentials: (
    id: string,
    data: Partial<GameCredentials>
  ) =>
  ({
    method: Method.PUT,
    path: `/credentials/${id}`,
    body: { credential: data }
  } as Endpoint<any>),
  updateCredentials: (
    id: string,
    data: Partial<GameCredentials>,
    discordId?: string
  ) =>
  ({
    method: Method.PUT,
    path: `/scholarships/credentials/${id}?discordId=${discordId || ''
      }`,
    body: data
  } as Endpoint<GameCredentials>)
}

export const TOSEndpoints = {
  bySlug: (slug: string) =>
  ({
    method: Method.GET,
    path: `/games/${slug}/tos`
  } as Endpoint<Tos & { userAgreement: UserAgreement }>),
  acceptOrReject: ({
    slug,
    action
  }: {
    slug: string
    action: 'accept' | 'reject'
  }) =>
  ({
    method: Method.POST,
    path: `/scholarships/${action}`,
    body: {
      gameId: slug
    }
  } as Endpoint<Partial<Tos>>)
}

export const UserEmailEndpoints = {
  validateCode: (email: string, code: string) => ({
    path: '/auth/code/validate',
    method: Method.POST,
    body: { email, code },
  } as Endpoint),
  editCurrentEmailCode: (email: string) =>
  ({
    method: Method.GET,
    path: "/users/email"
  } as Endpoint),
  updateEmail: (email: string) =>
  ({
    method: Method.PUT,
    path: '/users/email',
    body: { email }
  } as Endpoint)
}

export const PasskeyEndpoints = {
  get: () =>
  ({
    method: Method.GET,
    path: '/auth/passkeys'
  } as Endpoint),
  remove: (id: number) =>
  ({
    method: Method.DELETE,
    path: `/auth/passkeys/${id}`
  } as Endpoint)
}

export const UserEndpoints = {
  byId: (id: string) => ({
    method: Method.GET,
    path: `/admin/users/${id}`
  }),
  paginatedUsers: (filter: IUseUsers) => ({
    method: Method.GET,
    path: `/admin/users?query=${filter.query ?? null}${filter.referralCountSort ? "&referralCountSort=" + filter.referralCountSort : ""}&offset=${filter.offset}&limit=${filter.limit}${filter.country ? "&country=" + filter.country : ""}`
  }),
  top3: {
    method: Method.GET,
    path: `/users/top?order=score&direction=desc&limit=5`
  },
  myRank: (from?: string, to?: string, slug?: string, parentType?: ParentType) => ({
    method: Method.GET,
    path: `/rankings/my_ranking?${parameterize({ from, to, slug, scoreable: parentType })}`
  }),
  generalRanking: (limit?: number, from?: string, to?: string, slug?: string) => ({
    method: Method.GET,
    path: `/rankings/general?${parameterize({ top: limit || 5, from, to, slug })}`
  }),
  tournamentRanking: (limit?: number, from?: string, to?: string, slug?: string, organizer?: string) => ({
    method: Method.GET,
    path: `/rankings/tournaments?${parameterize({ top: limit || 5, from, to, slug, organizer })}`
  }),
  questRanking: (limit?: number, from?: string, to?: string, slug?: string) => ({
    method: Method.GET,
    path: `/rankings/quests?${parameterize({ top: limit || 5, from, to, slug })}`
  }),
  userContentRanking: (limit?: number, from?: string, to?: string, slug?: string) => ({
    method: Method.GET,
    path: `/rankings/user-contents?t${parameterize({ top: limit || 5, from, to, slug })}`
  }),
  gamesWithScore: (scorable_type?: string) => ({
    method: Method.GET,
    path: `/games/with-score${scorable_type != '' ? "?scorable_type=" + scorable_type : ''}`
  }),
  update: (id: number, body: Partial<User>) => ({
    method: Method.PUT,
    path: `/users/${id}`,
    body
  }),
  updateAvatar: (id: number, formData: FormData) =>
  ({
    method: Method.PUT,
    path: `/users/${id}`,
    body: formData,
    isForm: true
  } as Endpoint<any>),
  connectWallet: (walletAddress: string, blockchainNetwork: string, walletProvider: string) =>
  ({
    method: Method.POST,
    path: '/v1/users/connect-wallet',
    body: { walletAddress, blockchainNetwork, walletProvider }
  } as Endpoint<{ walletAddress: string, blockchainNetwork: string, walletProvider: string }>),
  updateMyInterests: (interests: number[]) =>
  ({
    method: Method.PUT,
    path: `/users`,
    body: { user: { gameCategoryIds: interests } }
  } as Endpoint),
  updateMyOpportunities: (opportunities: number[]) =>
  ({
    method: Method.PUT,
    path: `/users`,
    body: { user: { opportunityIds: opportunities } }
  } as Endpoint),
  updateEmail: (email: string) =>
  ({
    method: Method.PUT,
    path: '/users/email',
    body: { email }
  } as Endpoint),
  count: () =>
  ({
    method: Method.GET,
    path: '/users/count',
  } as Endpoint),
}

export const QuestOwnerEndpoints = {
  byId: (id: string) => ({
    method: Method.GET,
    path: `/owners/${id}`
  }),
  all: () => ({
    method: Method.GET,
    path: `/quests/owners/`
  }),
  paginatedQuestOwners: ({
    query,
    offset,
    limit
  }: IUseQuestOwners) => ({
    method: Method.GET,
    path: `/owners?query=${query}&offset=${offset}&limit=${limit}`
  }),
  create: (data: Partial<QuestOwner>) =>
  ({
    method: Method.POST,
    path: '/owners',
    body: data
  } as Endpoint<Partial<QuestOwner>>),
  update: (id: string, data: Partial<QuestOwner>) =>
  ({
    method: Method.PUT,
    path: `/owners/${id}`,
    body: data
  } as Endpoint<Partial<QuestOwner>>),
  delete: (id: string) => ({
    method: Method.DELETE as Method.DELETE,
    path: `/owners/${id}`
  }),
}

export const QuestEndpoints = {
  byOwnerId: (slug: string) => ({
    method: Method.GET,
    path: `/quests?slug=${slug}`
  }),
  all: ({ slug, page }: { page: number, slug?: string }) => ({
    method: Method.GET,
    path: `/quests?${parameterize({ slug, page })}`
  }),
  claim: (questId: string) => ({
    method: Method.PATCH,
    path: `/quests/${questId}/claim`
  }),
  completeWithSecretWord: (questId: string, data: string) => ({
    method: Method.PATCH,
    path: `/quests/${questId}/completeWithSecretWord`,
    body: data
  }),
  completeTwitterQuest: (questId: string) => ({
    method: Method.PUT,
    path: `/quests/${questId}/complete-twitter-quest`
  }),
  obQuests: () => ({
    method: Method.GET,
    path: `/quests?${parameterize({ onboarding: true })}`
  }),
}

export const QuestEventsEndpoints = {
  all: () => ({
    method: Method.GET,
    path: '/admin/quests/events'
  }),
}

export const MarketPlaceEndpoints = {
  all: (type?: string): Endpoint<any> => ({
    method: Method.GET,
    path: !type ? '/items' : `/items?type=${type}`
  }),
  claim: (id: string, coupon?: string, secretWord?: string): Endpoint<any> => ({
    method: Method.PATCH,
    path: `/items/${id}/claim`,
    body: { coupon, secretWord }
  }),
  getUserStock: () => ({
    method: Method.GET,
    path: '/items/user_stock'
  }),
  delete: (id: string) => ({
    method: Method.DELETE as Method.DELETE,
    path: `/marketplace/items/${id}`
  }),
  create: (data: Partial<MarketPlaceItem>) => (
    {
      method: Method.POST,
      path: '/marketplace/item',
      body: data
    }),
  update: (id: string, data: Partial<MarketPlaceItem>) => (
    {
      method: Method.PUT,
      path: `/marketplace/items/${id}`,
      body: data
    }),
  stock: (id: string, data: FormData) => (
    {
      method: Method.POST,
      path: `/items/${id}/stock`,
      body: data,
      isForm: true
    }),
  generateStock: (id: string, quantity: number) => (
    {
      method: Method.POST,
      path: `/marketplace/items/${id}/coupons`,
      body: { quantity: quantity }
    }),
  purchaseWithCoupon: (id: string, coupon: string) => (
    {
      method: Method.PUT,
      path: `/marketplace/${id}/stock/${coupon}/validate`,
    }),
  getStockWithCoupons: (id: string) => ({
    method: Method.GET,
    path: `/items/${id}/stocks`
  }),
}

export const UserContentEndpoints = {
  create: (data: Partial<UserContent>) => (
    {
      method: Method.POST,
      path: '/user_contents',
      body: { userContent: data }
    }),
  update: (id: string, data: Partial<UserContent>) => (
    {
      method: Method.PUT,
      path: `/user_contents/${id}`,
      body: data
    }),
  index: (gameId: string, filter?: string, page?: number) => (
    {
      method: Method.GET,
      path: `/user_contents?gameId=${gameId}&filter=${filter}&page=${page || 1}`
    }
  ),
  indexAll: () => (
    {
      method: Method.GET,
      path: `/user_contents`
    }
  ),
  find: (id: string) => (
    {
      method: Method.GET,
      path: `/user_contents/${id}`
    }
  ),
  vote: (id: string, value: number) => (
    {
      method: Method.PUT,
      path: `/user_contents/${id}/vote`,
      body: { value }
    }
  )
}

export const rateGame = (slug: string, value: number) => {
  return {
    method: Method.POST,
    path: `/games/${slug}/vote`,
    body: { value }
  } as Endpoint
}

export const SegmentEndpoints = {
  assignDevice: (data: { segment: string, token: string }) => ({
    method: Method.POST,
    path: `/segments/assign`,
    body: data
  }),
}

export const DeviceEndpoints = {
  all: () => ({
    method: Method.GET,
    path: '/notifications/devices'
  }),
  subscribe: (data: Partial<Device>) => ({
    method: Method.POST,
    path: '/devices',
    body: { device: data }
  }),
}

export const UserWalletsEndpoints = {
  create: (data: Partial<UserWallet>): Endpoint => (
    {
      method: Method.POST,
      path: '/wallets',
      body: data
    }),
  update: (id: string, data: Partial<UserWallet>) => (
    {
      method: Method.PUT,
      path: `/users/wallets/${id}`,
      body: data
    }),
  index: () => (
    {
      method: Method.GET,
      path: `/users/wallets`
    }
  ),
  main: () => (
    {
      method: Method.GET,
      path: '/users/wallets/main'
    }
  ),
  find: (id: string) => (
    {
      method: Method.GET,
      path: `/users/wallets/${id}`
    }
  ),
  delete: (id: number) => (
    {
      method: Method.DELETE,
      path: `/wallets/${id}`
    }
  ),
  deposit: () => (
    {
      method: Method.GET,
      path: `/wallets/deposit`
    }
  ),
}

export const Web3 = {
  createWallet: (data: any) => (
    {
      method: Method.POST,
      path: '/wallets',
      body: data
    }),
  updateWAllet: (address: string, network: string, data: Partial<UserWallet>) => (
    {
      method: Method.PUT,
      path: `/tokens/wallets/${address}/${network}`,
      body: data
    }),
  index: (walletAddress?: string) => (
    {
      method: Method.GET,
      path: walletAddress ? `/tokens?walletAddress=${walletAddress}` : '/tokens'
    }
  ),
  myWallets: () => (
    {
      method: Method.GET,
      path: `/wallets`
    }
  ),
  myMainWallet: () => (
    {
      method: Method.GET,
      path: '/tokens/wallets/main'
    }
  ),
  findWallet: (id: string, network: string) => (
    {
      method: Method.GET,
      path: `/tokens/wallets/${id}/${network}`
    }
  ),
  deleteWallet: (id: number) => (
    {
      method: Method.DELETE,
      path: `/wallets/${id}`
    }
  ),
  assignTokenToUser: (id: string, walletAddress: string) => (
    {
      method: Method.POST,
      path: `/tokens/${id}/assign`,
      body: { walletAddress: walletAddress }
    }
  ),
  mintRequest: (tokenId: string, walletAddress: string) => (
    {
      method: Method.POST,
      path: `/tokens/${tokenId}/mint`,
      body: { walletAddress: walletAddress }
    }
  ),
  mintRequestStatus: (assignmentId: string) => (
    {
      method: Method.GET,
      path: `/token_assignments/${assignmentId}`
    }
  ),
  show: (id: string, walletAddress: string) => (
    {
      method: Method.GET,
      path: `/tokens/${id}?address=${walletAddress}`
    }
  )
}

export const TournamentsEndpoints = {
  all: ({ page, limit, title, game, sortByStartDate, organizerId }: IUseTournaments) => ({
    method: Method.GET,
    path: `/tournaments?${parameterize({ page, limit, title, game, sortByStartDate, organizerId })}`
  }),
  allFromTo: (from: string, page?: number, sortStartDate?: string) => ({
    method: Method.GET,
    path: `/tournaments?page=${page || 1}&from=${from}&sortStartDate=${sortStartDate || 'asc'}`
  }),
  byId: (id: string) => ({
    method: Method.GET,
    path: `/tournaments/${id}`
  }),
  filter: (q: FilterTournamentsParams) => ({
    method: Method.GET,
    path: `/tournaments?page=${q.page || 1}&sortStartDate=${q.sortStartDate || 'asc'}&slug=${q.slug || ''}&isCanceled=${q.isCanceled ?? ''}`
  }),
  games: () => ({
    method: Method.GET,
    path: `/tournaments/games-with-tournament`
  }),
  create: (data: CreateTournamentParams) => ({
    method: Method.POST,
    path: '/tournaments',
    body: { tournament: data }
  }),
  update: (id: string, data: UpdateTournamentData) => ({
    method: Method.PUT,
    path: `/tournaments/${id}`,
    body: data
  }),
  delete: (id: string) => ({
    method: Method.DELETE,
    path: `/tournaments/${id}`
  }),
  registerIntention: (id: string, referredId?: string) => (
    TournamentsEndpoints.addInscription(id, 'Intention', referredId)
  ),
  registerParticipation: (id: string, referredId?: string) => (
    TournamentsEndpoints.addInscription(id, 'Participation', referredId)
  ),
  addInscription: (id: string, type: 'Intention' | 'Participation', referredId?: string) => ({
    method: Method.POST,
    path: `/tournaments/${id}/inscriptions`,
    body: {
      inscription: {
        type,
        ...(referredId && { referredId })
      }
    }
  }),
  organizers: (all: boolean = true) => ({
    method: Method.GET,
    path: `/tournaments/organizers?all=${all}`
  }),
}

export const TokensEndpoints = {
  all: () => ({
    method: Method.GET,
    path: '/tokens/admin'
  }),
  filter: (q: ListTokenData) => ({
    method: Method.GET,
    path: `/tokens?network=${q.network || ''}&type=${q.type || ''}&classification=${q.classification || ''}&section=${q.section || ''}&page=${q.page || 1}`
  }),
  getAssignments: (id: string, page?: number) => ({
    method: Method.GET,
    path: `/tokens/${id}/assignments?page=${page || 1}`
  }),
  removeUserFromWhitelist: (id: string) => ({
    method: Method.DELETE,
    path: `/tokens/assignments/${id}`,
  }),
}

export type { ListTokenData, CreateTokenData, UpdateTokenData };

export const OlaggNewsEndpoints = {
  find: (slug: string, show_unpublished = false, force_update = false, entityName = 'all'): Endpoint => ({
    method: Method.GET,
    path: `/news/${slug}?${show_unpublished ? '&show_unpublished=true' : ''}${force_update ? '&force_update=true' : ''}${entityName ? `&entity_name=${entityName}` : ''}`
  }),
  last: (filter: IUseNews): Endpoint => ({
    method: Method.GET,
    path: `/news/last?${parameterize(filter)}`
  }),
  search: (filter: IUseNews): Endpoint => ({
    method: Method.GET,
    path: `/news/search?${parameterize(filter)}`
  }),
  multiSearch: (filter: IUseNews): Endpoint => ({
    method: Method.GET,
    path: `/news/multi_search?${parameterize(filter)}`
  }),
  ranking: (): Endpoint => ({
    method: Method.GET,
    path: `/news/ranking`
  }),
  getVote: (newsId: string) => ({
    method: Method.GET,
    path: `/news/${newsId}/vote`
  }),
  getVotes: (newsId: string, page?: number) => ({
    method: Method.GET,
    path: `/news/${newsId}/votes?page=${page || 1}`
  }),
  vote: (newsId: string, value: number, entityName = 'all'): Endpoint => ({
    method: Method.POST,
    path: `/news/${newsId}/vote${entityName ? `?entity_name=${entityName}` : ''}`,
    body: {
      value: value
    }
  }),
}

export const ReferralsEndpoints = {
  all: (): Endpoint => ({
    method: Method.GET,
    path: `/relevant_actions`
  }),
  validateAction: (referrerId: string): Endpoint => ({
    method: Method.POST,
    path: `/relevant_actions/${referrerId.split('T')[0]}/validate?referredId=${referrerId.split('T')[1]}`
  }),
}

export const AlertsEndpoints = {
  get: (path: string): Endpoint => ({
    method: Method.GET,
    path: `/alerts?path=${path}`
  }),
}

export const RolesEndpoints = {
  roles: (filter: { page: number, limit: number }): Endpoint => ({
    method: Method.GET,
    path: `/roles?${parameterize({ ...filter })}`
  }),
  rolesPermissions: (filter: { page: number, limit: number }): Endpoint => ({
    method: Method.GET,
    path: `/roles_permissions?${parameterize({ ...filter })}`
  }),
}

export const OpportunitiesEndpoints = {
  all: (): Endpoint => ({
    method: Method.GET,
    path: `/opportunities`
  }),
}

export const RouletteEndpoints = {
  all: (): Endpoint => ({
    method: Method.GET,
    path: `/roulette`
  }),
  spin: (): Endpoint => ({
    method: Method.GET,
    path: `/roulette/spin`
  }),
}

export const PartnersEndpoints = {
  by_slug: (slug: string): Endpoint => ({
    method: Method.GET,
    path: `/owners/${slug}`
  })
}

export const TokenDropEndpoints = {
  by_slug: (slug: string): Endpoint => ({
    method: Method.GET,
    path: `/token-drops/${slug}`
  }),
}

export type IPaymentsGetAllProps = {
  status?: string;
  page: number;
  limit: number;
};

export type IPollsGetAllProps = {
  date?: string;
  visible?: string;
  page: number;
  limit: number;
};

export const NotificationsEndpoints = {
  get: (filter: IUseNotifications): Endpoint => ({
    method: Method.GET,
    path: `/notifications?${parameterize(filter)}`
  }),
  markAsRead: (id: string): Endpoint => ({
    method: Method.POST,
    path: `/notifications/${id}/mark_as_read`
  }),
  markAsUnread: (id: string): Endpoint => ({
    method: Method.POST,
    path: `/notifications/${id}/mark_as_unread`
  }),
  markAllAsRead: (): Endpoint => ({
    method: Method.POST,
    path: `/notifications/mark_all_as_read`
  }),
}

export const MyPaymentsEndpoints = {
  all: (filter: IPaymentsGetAllProps) => ({
    method: Method.GET,
    path: `/payments?${parameterize(filter)}`
  }),
  update: (id: any, data: any) =>
  ({
    method: Method.PUT,
    path: `/payments/${id}`,
    body: data
  } as Endpoint<any>),
}

export const TreasuresGameEndpoints = {
  index: (params: IUseTreasuresGame): Endpoint => ({
    method: Method.GET,
    path: `/treasures_games?${parameterize(params)}`
  }),
  show: (slug: string): Endpoint => ({
    method: Method.GET,
    path: `/treasures_games/${slug}`
  }),
  play: (slug: string, row: number, column: number): Endpoint => ({
    method: Method.POST,
    path: `/treasures_games/${slug}/play`,
    body: { row, column }
  }),
  gridStatus: (slug: string): Endpoint => ({
    method: Method.GET,
    path: `/treasures_games/${slug}/grid_status`
  }),
  playerStatus: (slug: string): Endpoint => ({
    method: Method.GET,
    path: `/treasures_games/${slug}/player_status`
  }),
  winnersInfo: (slug: string): Endpoint => ({
    method: Method.GET,
    path: `/treasures_games/${slug}/winners_info`
  }),
}

export const PollsEndpoints = {
  all: (filter: IPollsGetAllProps) => ({
    method: Method.GET,
    path: `/surveys?${parameterize(filter)}`
  }),
  bySlug: (slug: string) => ({
    method: Method.GET,
    path: `/surveys/${slug}`
  })
}

export const UserPreferencesEndpoints = {
  gameCategories: {
    all: () => ({
      method: Method.GET,
      path: `/game_categories`
    }),
    create: (data: any) =>
    ({
      method: Method.POST,
      path: '/game_categories',
      body: data
    } as Endpoint<any>),
    delete: (id: any) => ({
      method: Method.DELETE as Method.DELETE,
      path: `/game_categories/${id}`
    })
  },
  opportunities: {
    all: () => ({
      method: Method.GET,
      path: `/opportunities`
    }),
    create: (data: any) =>
    ({
      method: Method.POST,
      path: '/opportunities',
      body: data
    } as Endpoint<any>),
    delete: (id: any) => ({
      method: Method.DELETE as Method.DELETE,
      path: `/opportunities/${id}`
    })
  },
  platforms: {
    all: () => ({
      method: Method.GET,
      path: `/gaming_platforms`
    }),
    create: (data: any) =>
    ({
      method: Method.POST,
      path: '/gaming_platforms',
      body: data
    } as Endpoint<any>),
    delete: (id: any) => ({
      method: Method.DELETE as Method.DELETE,
      path: `/gaming_platforms/${id}`
    })
  }
}

export const StoryCategoriesEndpoints = {
  index: (params: IUseStoryCategories): Endpoint => ({
    method: Method.GET,
    path: `/story_categories?${parameterize(params)}`
  })
}

export const StoryEndpoints = {
  show: (id: number): Endpoint => ({
    method: Method.GET,
    path: `/stories/${id}`
  }),
  markHomeStoriesViewed: (): Endpoint => ({
    method: Method.PUT,
    path: `/users/update_home_stories_viewed`
  })
}