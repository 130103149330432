import { Google, Twitter, Discord } from "@olagg/ui-kit";

const checkInApp = () => {
  if (navigator.userAgent.includes('FBAN') || navigator.userAgent.includes('FBAV') || navigator.userAgent.includes('Instagram')) {
    return true
  } else {
    return false
  }
}

const AUTH_METHODS = {
  google: {
    icon: Google,
    text: "google",
    active: !checkInApp()
  },
  /* facebook: {
    icon: Facebook
  }, */
  discord: {
    icon: Discord,
    text: "discord",
    active: true
  },
  /*twitter: {
    icon: Twitter
  }*/
}

const STEPS = {
  none: 'none',
  chooseMethod: 'chooseMethod',
  verificationCode: 'verificationCode',
  setPasskey: 'setPasskey',
  completeData: 'completeData',
  linkDiscord: 'linkDiscord',
  welcomeProfileEdit: 'welcomeProfileEdit',
  platformsPreferences: 'platformsPreferences',
  opportunitiesPreferences: 'opportunitiesPreferences',
  gameCategoriesPreferences: 'gameCategoriesPreferences',
  completedModal: 'completedModal'
}

export { AUTH_METHODS, STEPS }
