import useSWR from 'swr'
import { BareFetcher, PublicConfiguration } from 'swr/dist/types'
import { BackendError, Endpoint } from '../endpoints/constants'
import { fetcher } from '../fetcher'

/**
 * @template B The shape of the data to be sent.
 * @template R The shape of the data to be received.
 * @param endpoint The endpoint to be used.
 * You can just pass the first type parameter for queries that
 * don't use a body, it'll be used as the type of the response
 * automatically
 * */
export const useQ = <B, R = B>(
  endpoint: Endpoint<B>,
  config?: Partial<
    PublicConfiguration<R, BackendError, BareFetcher<R>>
  >
) => {
  const { data, error, mutate } = useSWR<R, BackendError>(
    endpoint.path,
    async () => await fetcher<B, R>(endpoint),
    config
  )

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate
  }
}
