import { AlertsEndpoints, useQ } from "@olagg/api-hooks";
import { Alert } from "@olagg/db-types";
import { useLocation } from "react-router-dom";

export const useAlert = ({
    customPath
}: {
    customPath?: string
}) => {
    const { pathname } = useLocation()

    const response = useQ<{alert: Alert}>(AlertsEndpoints.get(customPath || pathname));
    
    return response;
}
