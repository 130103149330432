import { Badge, Flex, Image, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { Spinner } from '@chakra-ui/react'
import { Xp } from '@olagg/ui-kit/svg/Xp'
import avatar from '@olagg/ui-kit/svg/avatar_circle.svg'
import { useAuthStore } from '@olagg/store'
import { useTranslation } from 'react-i18next'

const UserNav = () => {
    const { me } = useAuthStore()
    const [imgAvatar, setImgAvatar] = useState(avatar)
    const [isLoading, setIsLoading] = useState(true)
    const { t } = useTranslation()

    useEffect(() => {
        setImgAvatar(me?.avatar || avatar)
        setIsLoading(false)
    }, [me])


    return (
        <>
            <Flex>
                {
                    isLoading ? (
                        <Flex height={'48px'} width={'48px'} alignItems={'center'} justifyContent='center'>
                            <Spinner color='white' size={'sm'}/>
                        </Flex>
                    ) : (
                        <>
                            <Flex direction={'row'}
                                gap={'8px'}
                                alignItems='center'
                                justifyContent={'center'}
                                marginLeft='8px'
                            >
                                <Flex pt='4px'>
                                    <Flex flexDirection={'column'} alignItems='center'>
                                        <Badge
                                            sx={{
                                                bgColor: '#D59A27',
                                                borderRadius: '50px',
                                                color: 'white',
                                                width: 'auto',
                                                height: '18px',
                                                fontSize: '12px',
                                                fontWeight: '600',
                                                textAlign: 'center',
                                                textTransform: 'inherit',
                                                paddingX: '10px'
                                            }}
                                        >
                                            {t('navbar.myAccountNav.level')} {me?.level.value}
                                        </Badge>
                                        <Flex alignItems={'center'}>
                                            <Text color={'white'} fontSize='14px' fontWeight='600'>
                                                {me?.level.score || 0}
                                            </Text>
                                            <Text w='25px'>
                                                <Xp transparentBackground />
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Flex
                                    width='48px'
                                    minWidth='48px'
                                    height='48px'
                                    minHeight='48px'
                                >
                                    <Image
                                        loading='lazy'
                                        fallbackSrc={avatar}
                                        width='100%'
                                        height='100%'
                                        src={imgAvatar}
                                        objectFit={'cover'} borderRadius='50%'
                                    />
                                </Flex>
                            </Flex>
                        </>
                    )}
            </Flex>
        </>
    )
}

export default UserNav
