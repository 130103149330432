import { STEPS } from "../constants";
import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text, useToast, Button, Show } from "@chakra-ui/react";

import { ROUTES } from "../../../routes";
import { analytics } from "../../../analytics";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuthStore, useOnboardingStore } from "@olagg/store";
import { AdTrackingContext } from '@/contexts/adTrackingContext';
import { useTranslation } from "react-i18next";
import Stepper from "./Stepper";
import OpportunityItem from "./OpportunityItem";
import { UserPreferencesEndpoints, fetcher } from "@olagg/api-hooks";
import { JobOpportunity } from '@olagg/db-types'


interface Props {
  isOpen?: boolean,
  isProfile?: boolean,
  onClose?: () => void
}

const OpportunitiesPreferences = ({
  isOpen = false,
  isProfile = false,
  onClose,
}: Props) => {
  const { fcb, fbp } = useContext(AdTrackingContext)
  const { t, i18n } = useTranslation();
  const lang = i18n.language
  const { isOnboardingOpen, step, setStep, closeOnboarding, providerSignedIn, obXp, setObXp, obQuests } = useOnboardingStore()
  const { me, getMe } = useAuthStore()
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [selectedArray, setSelectedArray] = useState([])
  const [addedOne, setAddedOne] = useState(false)
  const toast = useToast()

  const getData = async () => {
    await fetcher(UserPreferencesEndpoints.opportunities.all())
      .then((response) => {
        const { opportunities } = response
        setData(opportunities)
      })
  }

  const getXp = () => {
    const triggerEvent = 'user.opportunity.added'
    let totalXp = obXp
    let quest = obQuests?.quests.filter(f => f.trigger == triggerEvent)

    if (quest.length > 0) {
      totalXp += quest[0].score
    }
    setObXp(totalXp)
  }

  const removeItem = async (item) => {
    await fetcher(UserPreferencesEndpoints.opportunities.delete(item.id)).then(response => {
      getMe()
      triggerAnalytics(selectedArray.filter(i => i !== item))
    }).catch(error => {
      console.log('ERROR remove', error)
    })
  }

  const addItem = async (item) => {
    let data = { id: item.id }
    await fetcher(UserPreferencesEndpoints.opportunities.create(data)).then(response => {
      getMe()
      triggerAnalytics([...selectedArray, item])
      if (!addedOne) {
        setAddedOne(true)
        getXp()
      }

    }).catch(error => {
      console.log('ERROR add', error)
    })
  }

  const handleSelection = async (item) => {
    setSelectedArray(prevState => {
      if (prevState.includes(item)) {
        //FETCH DESTROY ITEM
        removeItem(item)
        return prevState.filter(i => i !== item);
      } else {
        //FETCH ADD/CREATE ITEM
        addItem(item)
        return [...prevState, item];
      }
    });
  }

  useEffect(() => {
    getData()
    getMe()
  }, [])

  const triggerAnalytics = (data: any) => {
    const currentUser = me
    let newIdentifyData = data.map(item => item.name);
    let props = {
      opportunities: newIdentifyData,
      user_agent: navigator.userAgent
    }
    if (fcb != null) props.fbc = fcb
    if (fbp != null) props.fbp = fbp
    analytics()
      ?.track('Opportunities Preferences Updated', props)

    analytics()
      ?.identify(`${currentUser.id}`, {
        // email: currentUser.email,
        // name: currentUser.name,
        // google_id: currentUser.authProviderData?.hasOwnProperty('google') ? currentUser.authProviderData.google.id : '',
        // discord_id: currentUser.authProviderData?.hasOwnProperty('discord') ? currentUser.authProviderData.discord.id : '',
        // game_categories: currentUser?.gameCategories && currentUser?.gameCategories.map(item => item.name) || [],
        opportunities: newIdentifyData,
        // platforms: currentUser?.gamingPlatforms && currentUser?.gamingPlatforms.map(item => item.name) || [],
        // level: currentUser.level?.value,
        // xp: currentUser.level?.score,
        // identity_provider: providerSignedIn,
        user_agent: navigator.userAgent
      })
  }

  const handleNext = () => {
    setStep(STEPS.gameCategoriesPreferences)
  }

  const closeModal = () => {
    if (!isProfile) {
      closeOnboarding()
    } else {
      onClose()
    }
  }

  const { pathname } = useLocation()
  const isLoginPath = pathname.includes(ROUTES.LOGIN)

  return (
    <Modal
      isOpen={(isOnboardingOpen && step === STEPS.opportunitiesPreferences) || isOpen}
      onClose={closeModal}
      isCentered
      size={isProfile ? '4xl' : 'full'}
      scrollBehavior='outside'
      closeOnEsc={!isLoginPath}
      closeOnOverlayClick={!isLoginPath}
    >
      <ModalOverlay backdropFilter='blur(10px)' />
      <ModalContent bg='transparent'
        height={isProfile ? {base: '100%', md: '70%'}: '100%'}
        width={isProfile ? {base: '100%', md: '70%'}: '100%'}
        overflow={{ base: 'auto', md: 'hidden' }}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <ModalBody
          padding={0}
          sx={{
            backgroundColor: '#0C0324',
            background: 'url(/images/bg_modal.png)',
            backgroundSize: 'cover'
          }}
          m='0'
          display={'flex'}
          flexDirection={'row'}
          w='100%'
          h='100%'
          overflow={'auto'}
          maxW={{ base: '100%', '2xl': '1280' }}
          maxH={{ base: '100%', '2xl': '720px' }}
        >
          <Flex flex='1' alignItems={'center'} p={{ base: '16px', md: '32px' }} flexDirection={'column'} position='relative'>
            {!isLoginPath && <ModalCloseButton bg='transparent' borderRadius="50%" top='10px' right="10px" zIndex='2' color={'white'} />}

            <Flex flex='0 0 auto' flexDir={'column'} alignItems='center' w={'100%'}>
              {!isProfile && (<Stepper currentStep={3} totalSteps={4} />)}
              <Flex flexDirection={'column'} alignItems='center' mt='24px' mb='48px' maxW={'780px'}>
                <Text color='white' fontSize={{ base: '20px', md: '30px' }} fontWeight='700' textAlign={'center'}>
                  {!isProfile && (
                    <>
                      {t('OnBoarding.OpportunitiesPreferences.title1')}
                      < br />
                    </>
                  )}
                  {t('OnBoarding.OpportunitiesPreferences.title2')}
                </Text>
              </Flex>
            </Flex>

            <Flex flex='1 1 auto' overflowY={'auto'} position='relative'>
              <Flex
                flexWrap={'wrap'}
                gap={{ base: '16px', md: '32px' }}
                justifyContent='center'
                alignItems='flex-start'
                w='full'
              >
                <Flex
                  flexWrap={'wrap'}
                  gap={{ base: '16px', md: '32px' }}
                  justifyContent='center'
                  alignItems='flex-start'
                  paddingX={{ base: 0, md: '5%' }}
                  w='full'
                >
                  {data.map((d, i) => (
                    <Flex whiteSpace='nowrap' key={i} cursor='pointer' width={'auto'} height={'68px'} textAlign='center' onClick={() => handleSelection(d)}>
                      <OpportunityItem selected={selectedArray.includes(d) || me?.opportunities.some(item => item.id === d.id)} image={d.image} text={
                        lang == 'es' ? d.name : (d?.extraData && d?.extraData[lang]?.name) ? d?.extraData[lang]?.name : d.name
                      } />
                    </Flex>
                  ))}
                  <Flex bgColor={'transparent'} height='80px' width={'100%'}/>
                </Flex>
              </Flex>
            </Flex>

            {!isProfile ? (
              <Flex flex='0 0 auto' width={'full'}>
                <Flex position={'relative'} zIndex='3' mt={'auto'} width='100%' flexDirection={'row'} alignItems='center' gap='16px' pt='32px' mb={'16px'}>
                  <Show above="md">
                    <Button
                      type='submit'
                      width={'100%'}
                      variant='unstyled'
                      fontSize={'14px'}
                      maxW='260px'
                      color={'white'}
                      onClick={closeOnboarding}
                    >
                      {t('OnBoarding.Common.skip')}
                    </Button>
                  </Show>
                  <Button
                    ml={{ base: 'inherit', md: 'auto' }}
                    type='submit'
                    width={'100%'}
                    variant={{ base: 'link', md: 'outline' }}
                    fontSize={'14px'}
                    maxW={{ base: '100px', md: '260px' }}
                    color={'white'}
                    sx={{
                      color: 'white !important'
                    }}
                    onClick={() => setStep(STEPS.platformsPreferences)}
                  >
                    {t('OnBoarding.Common.back')}
                  </Button>
                  <Button
                    type='submit'
                    width={'100%'}
                    variant={'filledGradient'}
                    fontSize={'14px'}
                    maxW={{ base: '150px', md: '260px' }}
                    onClick={handleNext}
                    ml={{ base: 'auto', md: 'inherit' }}
                  >
                    {t('OnBoarding.Common.continue')}
                  </Button>
                </Flex>
              </Flex>
            ) : (
              <Flex flex='0 0 auto' width={'full'}>
                  <Flex position={'relative'} zIndex='3' justifyContent='center' mt={'auto'} width='100%' flexDirection={'row'} alignItems='center' gap='16px' pt='32px' mb={'16px'}>
                    <Button
                      type='submit'
                      width={'100%'}
                      variant={'filledGradient'}
                      fontSize={'14px'}
                      maxW={{ base: '100%', md: '260px' }}
                      onClick={closeModal}
                    >
                      {t('OnBoarding.Common.update')}
                    </Button>
                  </Flex>
                </Flex>
            )}
{/* 
            {!isProfile && (
              <Flex sx={{
                background: 'linear-gradient(0deg, #0C0324 30%, rgba(12, 3, 36, 0.00) 99%)',
                position: 'absolute',
                zIndex: 2,
                bottom: '1px',
                width: '100%',
                height: '220px'
              }} />
            )} */}

          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default OpportunitiesPreferences;
