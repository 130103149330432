
    if (import.meta.env.VITE_SEGMENT_KEY) {
      try {
        var isPrerender = navigator.userAgent.toLowerCase().indexOf('prerender') !== -1;
        if (!isPrerender) {

          !function () {
            var i = "analytics", analytics = window[i] = window[i] || []; if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else {
              analytics.invoked = !0; analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "screen", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware", "register"];
              analytics.factory = function (e) {
                return function () {
                  if (window[i].initialized)
                    return window[i][e].apply(window[i], arguments);
                  var n = Array.prototype.slice.call(arguments);
                  n.unshift(e);
                  analytics.push(n); return analytics
                }
              };
              for (var n = 0; n < analytics.methods.length; n++) {
                var key = analytics.methods[n];
                analytics[key] = analytics.factory(key)
              }
              analytics.load = function (key, n) {
                var t = document.createElement("script");
                t.type = "text/javascript";
                t.async = !0;
                t.setAttribute("data-global-segment-analytics-key", i);
                t.src = "https://analytics-cdn.olagg.io/analytics.js/v1/" + key + "/analytics.min.js";
                var r = document.getElementsByTagName("script")[0];
                r.parentNode.insertBefore(t, r);
                analytics._loadOptions = n
              };
              analytics._writeKey = import.meta.env.VITE_SEGMENT_KEY;
              analytics.SNIPPET_VERSION = "5.2.0";
              analytics._cdn = "https://analytics-cdn.olagg.io";
              analytics._loadOptions = {
                obfuscate: true,
                integrations: {
                  'Segment.io': {
                    apiHost: 'analytics.olagg.io/v1',
                    protocol: 'https' // optional
                  }
                }
              };
            }
          }();
        }
      } catch (e) {
        console.log('error loading analytics', e)
      }
    }
  