export type BackendError = {
    message: string
    error?: string | object
    status: string
    extraData?: object
}

export enum Method {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH'
}

/**
 * @param B The shape of the data to be sent.
 */
export type Endpoint<B = null | any> = {
    method: Method
    path: string
    body?: B
    headers?: Record<string, string>
    isForm?: boolean
}
