import { createContext, useState, useEffect } from 'react';
import Cookies from "js-cookie";

interface IAdTrackingContext {
    fcb: string | null;
    fbp: string | null;
}

export const AdTrackingContext = createContext({} as IAdTrackingContext);

export const AdTrackingProvider = ({ children }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const [fcb, setFcb] = useState<string | null>(null)
    const [fbp, setFbp] = useState<string | null>(null)

    const getAdTracking = () => {
        const _fbp = Cookies.get('_fbp')
        if (_fbp) setFbp(_fbp)
        const fbclid = urlParams.get('fbclid')
        if (fbclid) setFcb(fbclid)
    }

    useEffect(() => {
        getAdTracking()
    }, [])

    return (
        <AdTrackingContext.Provider
            value={{
                fcb,
                fbp
            }}>
            {children}
        </AdTrackingContext.Provider>
    );
};
