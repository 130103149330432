import { Flex } from "@chakra-ui/react";
import { FaCheck } from "react-icons/fa";

type IProps = {
  totalSteps: number,
  currentStep: number,
}

const Stepper = ({ totalSteps, currentStep }: IProps) => {

  const totalStepsArr = Array.from({ length: totalSteps }, (_, i) => i + 1)

  return (
    <>
      <Flex gap={'16px'}>

        {totalStepsArr.map(e => (
          <Flex
            sx={{
              width: '16px',
              height: '16px',
              border: currentStep == e || currentStep > e ? '4px solid #FEAD10' : '4px solid #C0C0C0',
              borderRadius: '50%',
              backgroundColor: currentStep > e ? '#FEAD10' : 'white',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {currentStep > e && <FaCheck color="#AB17DF" />}
          </Flex>
        ))}

      </Flex>

    </>
  )
}

export default Stepper;
